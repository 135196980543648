import React, { useEffect, useState } from "react";
import { SendPostRequest } from "../http";
import Spinner from "../components/Spinner";

function FruitsDetail({ fruitId }) {
  const [majorCategory, setMajorCategory] = useState("");
  const [singleFruitData, setSingleFruitData] = useState();

  useEffect(() => {
    const fetchSingleFruit = async () => {
      try {
        const response = await SendPostRequest("home/diet_items", {
          host: "drburhan.clinta.biz",
          item_id: fruitId,
        });
        setSingleFruitData(response.data);

        if (response.status === "success") {
        } else {
          console.error("Error fetching fruits:", response.message);
        }
      } catch (error) {
        console.error("Error fetching fruits:", error);
      }
    };

    fetchSingleFruit();
  }, [fruitId]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await SendPostRequest("home/diet_categories", {
          host: "drburhan.clinta.biz",
          type: "full",
        });
        setMajorCategory(response.data.category);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
      }
    };

    fetchData();
  }, []);

  return (
    <>
      {majorCategory?.length === 0 ? (
        <div
          style={{ marginBottom: "100px" }}
          className="d-flex align-items-center justify-content-center"
        >
          <Spinner />
        </div>
      ) : (
        <div className="ComparativeNutrients">
          <div className="row mb-5">
            <div
              className="col-lg-3"
              style={{
                backgroundImage: `url(${singleFruitData?.item_Detail?.Image})`,
                backgroundPosition: "center center",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                height: "200px",
              }}
            >
              {/* <img src={singleFruitData?.item_Detail?.Image} alt="Fruit Image" /> */}
            </div>
            <div className="col-lg-9">
              <h4 style={{ textTransform: "capitalize" }}>
                {singleFruitData?.item_Detail?.Category} -{" "}
                {singleFruitData?.item_Detail?.Name} -{" "}
                {singleFruitData?.item_Detail?.UrduName}
              </h4>

              <hr />
              <p>{singleFruitData?.item_Detail?.Description}</p>
            </div>
          </div>

          <div className="items">NUTRITIONAL ITEMS</div>

          {singleFruitData &&
            Object.entries(singleFruitData?.diet_fact_detail).map(
              ([categoryKey, categoryValue], key) => (
                <table class="table">
                  <thead>
                    <tr>
                      <th colspan="4">{categoryKey.toUpperCase()}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {categoryValue.map((item, key) => (
                      <tr key={key}>
                        <td style={{ width: "50%" }}>
                          {item.title.toUpperCase()}
                        </td>
                        <td className="text-center">
                          {item.val} {item.val ? item.unit : "--"}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )
            )}
        </div>
      )}
    </>
  );
}

export default FruitsDetail;
