import React, { useState } from "react";
import { useSelector } from "react-redux";
import { SendPostRequest } from "../../http";
import { toast } from "react-toastify";

function UpdateAccount({ onTabSelect }) {
  const patientData = useSelector((store) => store.patient.patientLogin);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [password, setPasspassword] = useState({
    old_password: "",
    new_password: "",
    confirm_password: "",
  });

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setPasspassword((prv) => ({
      ...prv,
      [name]: value,
    }));
    if (name === "confirm_password" && value !== password.new_password) {
      setError("Password does not match");
    } else {
      setError(null);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password.new_password !== password.confirm_password) {
      setError("Password does not match");
    } else {
      setError(null);
    }
    setLoading(true);
    try {
      const response = await SendPostRequest("healthcare/update-account", {
        logged_id: patientData?.logged_id,
        form_data: {
          old_password: password.old_password,
          new_password: password.new_password,
        },
      });
      if (response.status === "success") {
        toast.success(response.message);
        setPasspassword({
          old_password: "",
          new_password: "",
          confirm_password: "",
        });
        console.log("Password updated successfully");
        onTabSelect("WeightManagment");
      } else {
        toast.error(response.message);
        console.error(
          "Failed to update password",
          response.error || "Unknown error"
        );
      }
    } catch (error) {
      console.error("Error updating password:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      {/*<p>UpdateAccount</p>*/}
      <div className="container">
        <form className="row" onSubmit={handleSubmit}>
          <div className="col-lg-12">
            <h4 className="cs_fs_20 cs_medium mb-0">Update Account</h4>
            <div className="cs_height_42 cs_height_xl_25"></div>
            <div className="row">
              <div className="col-lg-6">
                {/* <h>Email</h> */}
                <label className="cs_input_label cs_heading_color fw-bold">
                  Email
                </label>
                <p>{patientData?.email}</p>
                <div className="cs_height_42 cs_height_xl_25"></div>
              </div>

              <div className="col-lg-6">
                <label className="cs_input_label cs_heading_color">
                  Current Password
                </label>
                <input
                  required
                  type="password"
                  className="updateprofile_input"
                  placeholder="Enter Old Password"
                  name="old_password"
                  value={password.old_password}
                  onChange={handleOnChange}
                />
                <div className="cs_height_42 cs_height_xl_25"></div>
              </div>
              <div className="col-lg-6">
                <label className="cs_input_label cs_heading_color">
                  New Password
                </label>
                <input
                  required
                  type="password"
                  className="updateprofile_input"
                  placeholder="Enter New Password"
                  name="new_password"
                  value={password.new_password}
                  onChange={handleOnChange}
                />
                <div className="cs_height_42 cs_height_xl_25"></div>
              </div>
              <div className="col-lg-6">
                <label className="cs_input_label cs_heading_color">
                  Confirm New Password
                </label>
                <input
                  required
                  type="password"
                  className={`updateprofile_input ${error && "border-danger"}`}
                  placeholder="Confirm New Password"
                  name="confirm_password"
                  value={password.confirm_password}
                  onChange={handleOnChange}
                />
                <div className="cs_height_42 cs_height_xl_25 text-danger">
                  {error}
                </div>
              </div>

              <div className="col-lg-12 update_profile_btn_wrapper">
                <div className="cs_height_18"></div>
                <button
                  disabled={loading}
                  className="update_profile_btn"
                  type="submit"
                >
                  {loading ? "Submitting..." : "Submit"}
                </button>
              </div>
            </div>
            {/* <div className="cs_height_42 cs_height_xl_25"></div>

            <h4 className="cs_fs_32 cs_medium mb-0">Connectivity Pin Code</h4>
            <div className="cs_height_42 cs_height_xl_25"></div>

            <div className="row">
              <div className="col-lg-6">
                <label className="cs_input_label cs_heading_color">
                  Current Password
                </label>
                <input
                  type="password"
                  className="updateprofile_input"
                  placeholder="Enter Password"
                />
                <div className="cs_height_42 cs_height_xl_25"></div>
              </div>
              <div className="col-lg-6">
                <label className="cs_input_label cs_heading_color">
                  Security Pin (4 Digits)
                </label>
                <input
                  type="number"
                  className="updateprofile_input"
                  placeholder="Enter Security Pin"
                />
                <div className="cs_height_42 cs_height_xl_25"></div>
              </div>

              <div className="col-lg-12 update_profile_btn_wrapper">
                <div className="cs_height_18"></div>
                <button className="update_profile_btn">Submit</button>
              </div>
            </div> */}
          </div>
        </form>
      </div>
    </div>
  );
}

export default UpdateAccount;
