import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom"; // Import Link from React Router
import "./Main.css";
import { useSelector } from "react-redux";

export default function DoctorNav() {
  const [isScrolled, setIsScrolled] = useState(false);
  const patientLogin = useSelector((state) => state.patient.patientLogin);
  const sitedata = useSelector((store) => store.global.loginData);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    if (window.scrollY > 0) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };
  return (
    <>
      <nav className="cs_nav">
        <ul className="cs_nav_list">
          <li>
            <Link to="/">Home</Link>
          </li>
          <li className="menu-item-has-children">
            <Link to="#">About Me</Link>
            <ul>
              <li>
                <Link to="/about">Introduction</Link>
              </li>
              {sitedata?.options?.patient_nav === "show" ? (
                <li>
                  <Link to="/reviews">Reviews</Link>
                </li>
              ) : (
                <li></li>
              )}
              <li>
                <Link to="/gallery">Gallery</Link>
              </li>
            </ul>
          </li>
          <li className="menu-item-has-children">
            <Link to="#">Patient Care</Link>
            <ul>
              <li>
                <Link to="/patient-portal">Portal</Link>
              </li>
              <li>
                <Link to="/services">Services</Link>
              </li>
              <li>
                <Link to="/hospital-clinic/0">Hospital/Clinic</Link>
              </li>
            </ul>
          </li>
          <li>
            <Link to="/e_health">E Health</Link>
          </li>
          {/* <li>
            <Link to="/complaints">Complaint</Link>
          </li> */}
          <li>
            <Link to="/contact">Contact Us</Link>
          </li>
          <li>
            {patientLogin ? (
              <Link to="/patient">
                <button className="loginBorderd">My Account</button>
              </Link>
            ) : (
              <Link to="/login">
                <button className="loginBorderd">Login</button>
              </Link>
            )}
          </li>
          {/* <li>
            {patientLogin ? (
              <Link to="/patient">
                <button className={`${isScrolled ? "loginBorderd " : "login"}`}>
                  My Account
                </button>
              </Link>
            ) : (
              <Link to="/login">
                <button className={`${isScrolled ? "loginBorderd " : "login"}`}>
                  Login
                </button>
              </Link>
            )}
          </li> */}
        </ul>
      </nav>
    </>
  );
}
