import React, { useState, useEffect, useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { SendPostRequest } from "../../http";

function ReviewSlider({ clientReviews }) {
  const settings = {
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: true,
    // autoplay:true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  // const [clientReviews, setClientReviews] = useState([]);
  // const [expandedReviews, setExpandedReviews] = useState([]);
  const [reviewHeights, setReviewHeights] = useState([]);
  const [maxHeight, setMaxHeight] = useState(300);
  const reviewRef = useRef([]);

  // const getClientReviews = async () => {
  //   const response = await SendPostRequest("reviews");
  //   setClientReviews(response.data);
  //   setExpandedReviews(Array(response.data.length).fill(false));
  // };
  useEffect(() => {
    if (reviewRef.current.length > 0) {
      const heights = reviewRef.current.map((ref) => ref.clientHeight);
      setReviewHeights(heights);
    }
  }, [clientReviews]);

  useEffect(() => {
    // getClientReviews();
  }, []);

  const [expandedIndex, setExpandedIndex] = useState(null);
  const toggleExpandReview = (index) => {
    setExpandedIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.slice(0, maxLength) + "...";
    }
    return text;
  };

  return (
    <div className=" mt-5">
      <div className="reviews">
        <div className="container">
          <Slider {...settings}>
            {clientReviews.map((data, index) => (
              <div key={index} className="review-card">
                <div className="row">
                  <div className="col-lg-auto col-sm-12">
                    <div className="review-img">
                      <img
                        src={`${data?.profile_image}`}
                        alt=""
                        className="w-100"
                      />
                    </div>
                  </div>
                  <div className="col-lg-9 col-sm-12">
                    <h3 className="text-white ">{data.full_name}</h3>
                    <p className="review-decs">
                      {expandedIndex === index
                        ? data?.detail
                        : truncateText(data?.detail, 75)}
                      {data?.detail?.length > 75 && (
                        <a
                          className="cur-pointer"
                          onClick={() => toggleExpandReview(index)}
                        >
                          {expandedIndex === index ? " See Less" : "See More"}
                        </a>
                      )}
                    </p>
                  </div>
                  {/* {data?.detail?.length > 75 && (
                    <div className="see-more-text">
                      <a className="" onClick={() => toggleExpandReview(index)}>
                        {expandedIndex === index ? "See Less" : "See More"}
                      </a>
                    </div>
                  )} */}
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
}

export default ReviewSlider;
