import React, { useEffect, useState } from "react";
import "./MainBanner.css";
import Slider from "react-slick";
import { SendPostRequest } from "../../http";
import Spinner from "../Spinner";

function MainBanner({ isData, setIsdata }) {
  const [brandingBanners, setBrandingBanners] = useState([]);

  useEffect(() => {
    brandingData();
  }, []);

  const settings = {
    fade: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 6000,
    waitForAnimate: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  function handleSlideChange(currentSlide) {
    const textContainers = document.querySelectorAll(
      ".text-container.animated"
    );
    textContainers.forEach((container) => {
      container.classList.remove("animated");
      void container.offsetWidth;
      container.classList.add("animated");
    });
  }

  const brandingData = async () => {
    const response = await SendPostRequest("branding/banners");
    if (!response?.data || response?.data?.length < 1) {
      setIsdata(true);
    } else {
      setBrandingBanners(response?.data);
    }
  };

  return (
    <div>
      <div className="homer-banner-slider">
        <Slider {...settings} afterChange={handleSlideChange}>
          {brandingBanners?.length === 0 ? (
            <div
              style={{ marginBottom: "100px" }}
              className="d-flex align-items-center justify-content-center "
            >
              <Spinner />
            </div>
          ) : (
            brandingBanners?.length > 0 &&
            brandingBanners.map((item, index) => (
              <div
                // style={{backgroundImage: `url(data:image/jpeg;base64, ${item?.banner_image})`}}
                className="hero-container"
                key={index}
              >
                <img
                  src={`${item?.banner_image}`}
                  alt="Hero"
                  className="cs_hero_img"
                />

                <div className="overlay"></div>
                <div className="text-container animated">
                  <h1 className="">{item?.banner_title}</h1>
                  <p>{item?.banner_description}</p>
                </div>
              </div>
            ))
          )}
        </Slider>
      </div>
    </div>
  );
}

export default MainBanner;
