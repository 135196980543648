import React, { useEffect, useState } from "react";

import Footer from "../Footer";
import Header from "../Header";
// import { Link, useParams } from "react-router-dom";
import { SendPostRequest } from "../../http";
import createSlug from "../../utils/CreateSlug";
import Spinner from "../Spinner";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedDoctor } from "../../store/global/actions";
import { useNavigate } from "react-router-dom";
import { capitalize } from "@mui/material";

export default function Doctors() {
  const sitedata = useSelector((store) => store.global.loginData);

  const [doctorsData, setDoctorsData] = useState([]);
  const [speciality, setSpeciality] = useState([]);
  const [e_health, setEHealth] = useState("");
  const [filteredDoctors, setFilteredDoctors] = useState(null);
  const [eHealthFilter, setEHealthFilter] = useState(null);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    const title = sitedata?.site_title || "";
    document.title = capitalize(title) + " :: Doctors - ClinTa";
    window.scrollTo(0, 1);
    getDoctorsData();
  }, []);

  const getDoctorsData = async () => {
    const response = await SendPostRequest("doctors/list");
    setDoctorsData(response?.data);
    setFilteredDoctors(response?.data);
    setEHealthFilter(
      response?.data.filter((item) => item.telemedicine_id !== 0)
    );

    const uniqueDepartments = Array.from(
      new Set(
        response?.data.map((doctor) =>
          doctor?.speciality ? doctor?.speciality : "All"
        )
      )
    );
    setSpeciality(uniqueDepartments.sort());
  };

  const setSelectedDoctorData = (data) => {
    dispatch(setSelectedDoctor(data));
  };

  const [selectedCategory, setSelectedCategory] = useState("All");

  const handleFilterClick = (category) => {
    setSelectedCategory(category);
    setFilteredDoctors(() => {
      if (category === "All") {
        return doctorsData;
      } else if (category === "e_health") {
        return doctorsData?.filter(
          (doctorsData) => doctorsData?.telemedicine_id !== 0
        );
      } else {
        return doctorsData?.filter(
          (doctorsData) => doctorsData?.speciality === category
        );
      }
    });
  };

  const [backgroundImage, setBackgroundImage] = useState("");
  useEffect(() => {
    let themeColor;
    if (sitedata) {
      themeColor = sitedata?.theme_color?.split(".css").join("");
    } else {
    }

    setBackgroundImage(`/assets/img/doctors/banner_bg_${themeColor}.svg`);
  }, [sitedata?.theme_color]);
  return (
    <>
      <Header />

      <section
        className="cs_banner cs_style_5 cs_bg_filed"
        // style={{ backgroundImage: "url('/assets/img/doctors/banner_bg.svg')" }}
        style={{ backgroundImage: `url(${backgroundImage})` }}
      >
        {/* <div className="cs_banner_img">
          <img src="/assets/img/doctors/banner_img.png" alt="Banner" />
        </div> */}
        <div className="container">
          <div className="cs_banner_text">
            <h2 className="cs_banner_title cs_fs_72">Our Doctors</h2>
          </div>
        </div>
      </section>
      <div className="cs_height_42 cs_height_xl_25"></div>

      <div className="cs_height_42 cs_height_xl_25"></div>

      <h2 style={{ textAlign: "center" }}>MEET OUR SPECIALISTS</h2>

      <div className="cs_height_42 cs_height_xl_25"></div>

      <div className="container">
        <div className="cs_doctors_heading mt-4">
          <div className="cs_isotop_filter cs_style1">
            <ul className="cs_mp0">
              {speciality.map((speciality, index) => (
                <li
                  key={index}
                  className={selectedCategory === speciality ? "active" : ""}
                >
                  <button
                    type="button"
                    onClick={() => handleFilterClick(speciality)}
                  >
                    {speciality}
                  </button>
                </li>
              ))}
              <li className={selectedCategory === "e_health" ? "active" : ""}>
                <button
                  type="button"
                  onClick={() => handleFilterClick("e_health")}
                >
                  E-Health
                </button>
              </li>
            </ul>
          </div>
        </div>
        <div className="cs_has_gutter_24 mt-4">
          <div className="row mt-5">
            {filteredDoctors === null ? (
              <div
                style={{ marginBottom: "60px" }}
                className="d-flex align-items-center justify-content-center "
              >
                <Spinner />
              </div>
            ) : filteredDoctors?.length < 1 ? (
              <h1 className="text-center">No Doctor Found</h1>
            ) : (
              filteredDoctors?.length > 0 &&
              filteredDoctors?.map((doctor, index) => (
                <div
                  key={index}
                  className={`col-lg-3 col-md-6 col-12 doctor-detail-cards ${
                    selectedCategory === doctor.speciality ||
                    selectedCategory === e_health
                      ? "show"
                      : "hide"
                  } `}
                >
                  <div className="cs_team cs_style_1 cs_type_2 text-center cs_radius_20 overflow-hidden doctor-list-cards">
                    <div className="cs_member_img">
                      <a
                        onClick={(e) => {
                          e.preventDefault();
                          setSelectedDoctorData({
                            fullName: doctor?.full_name,
                            profilePic: doctor?.profile,
                            department: doctor?.department,
                          });
                          navigate(
                            `/doctor/${doctor?.uid}/${createSlug(
                              doctor?.full_name
                            )}`
                          );
                        }}
                        href={"/"}
                        className="d-block"
                      >
                        <div
                          style={{
                            // backgroundImage: `url(data:image/jpeg;base64,${doctor?.profile})`,
                            backgroundImage: `url(${doctor?.profile})`,
                            backgroundPosition: "top center",
                            backgroundSize: "cover",
                            backgroundRepeat: "no-repeat",
                            height: "380px",
                          }}
                        ></div>
                      </a>
                      <div className="cs_label cs_white_color cs_accent_bg">
                        {doctor?.department}
                      </div>
                    </div>
                    <h3 className="cs_member_name">
                      <a>{doctor?.full_name}</a>
                    </h3>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      </div>
      <div className="footer">
        <Footer />
      </div>
    </>
  );
}

{
  /* */
}
