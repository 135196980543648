import React, { useEffect, useState } from "react";
import { SendPostRequest } from "../http";
import { Link } from "react-router-dom";
import Spinner from "./Spinner";
import { formatDate } from "../Helper/Helper";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function HomeNews() {
  const [newsData, setNewsData] = useState([]);

  useEffect(() => {
    getNewsData();
  }, []);

  const getNewsData = async () => {
    const response = await SendPostRequest("news/list");
    setNewsData(response?.data);
  };

  const settings = {
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: true,
    // autoplay:true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div>
      {newsData && newsData?.length !== 0 && (
        <section>
          <div class="cs_height_190 cs_height_xl_145 cs_height_lg_105"></div>
          <div class="container">
            <div className="featured_services_heading_wrapper pb-5">
              <h2 className="cs_section_title cs_fs_65 m-0 ">Latest News</h2>
              <Link to="/news">
                <button className="featured_services_view_btn">View All</button>
              </Link>
            </div>
            <div class="cs_height_70 cs_height_lg_50"></div>

            <div className="featured_services_desktop">
              <div class="row gy-3">
                {newsData?.map((data, index) => (
                  <div key={index} className="col-lg-4 col-md-6 col-12">
                    <div className="cs_post cs_style_1 facility-card">
                      <div
                        style={{
                          backgroundImage: `url(${data?.image})`,
                          backgroundPosition: "top center",
                          backgroundSize: "cover",
                          backgroundRepeat: "no-repeat",
                          height: "250px",
                          width: "100%",
                          cursor: "pointer",
                        }}
                      />
                      <div className="cs_post_info">
                        <div>
                          <h2 className="cs_post_title cs_semibold cs_fs_32">
                            {data?.title}
                          </h2>

                          <div class="cs_post_meta author_detail_heading-wrapper mb-3">
                            <h3>Publish Date</h3>
                            <div class="cs_posted_by">
                              {formatDate(data?.date)}
                            </div>
                          </div>

                          <p
                            dangerouslySetInnerHTML={{
                              __html: data?.content?.slice(0, 110),
                            }}
                          />

                          <Link
                            to={`/news/detail/${data?.slug}`}
                            // to={`/service/${service?.service_slug}`}
                          >
                            <button className="service-read-more">
                              Read More
                            </button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div className="featured_services_mobile">
              <Slider {...settings}>
                {newsData?.length === 0 ? (
                  <div
                    style={{ marginBottom: "100px" }}
                    className="d-flex align-items-center justify-content-center "
                  >
                    <Spinner />
                  </div>
                ) : (
                  newsData?.map((data, index) => (
                    <div key={index} className="col-lg-4 col-md-6 col-12">
                      <div className="cs_post cs_style_1 facility-card">
                        <div
                          style={{
                            backgroundImage: `url(${data?.image})`,
                            backgroundPosition: "top center",
                            backgroundSize: "cover",
                            backgroundRepeat: "no-repeat",
                            height: "250px",
                            width: "100%",
                            cursor: "pointer",
                          }}
                        />
                        <div className="cs_post_info">
                          <div>
                            <h2 className="cs_post_title cs_semibold cs_fs_32">
                              {data?.title}
                            </h2>

                            <div class="cs_post_meta author_detail_heading-wrapper mb-3">
                              <h3>Publish Date</h3>
                              <div class="cs_posted_by">
                                {formatDate(data?.date)}
                              </div>
                            </div>

                            <p
                              dangerouslySetInnerHTML={{
                                __html: data?.content?.slice(0, 110),
                              }}
                            />

                            <Link
                              to={`/news/detail/${data?.slug}`}
                              // to={`/service/${service?.service_slug}`}
                            >
                              <button className="service-read-more">
                                Read More
                              </button>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                )}
              </Slider>
            </div>
          </div>
        </section>
      )}
    </div>
  );
}

export default HomeNews;
