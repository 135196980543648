import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";
import { SendPostRequest } from "../http";
import Spinner from "./Spinner";
import "./Main.css";
import { useSelector } from "react-redux";
import { capitalize } from "@mui/material";

export default function Services() {
  const sitedata = useSelector((store) => store.global.loginData);
  const [isData, setIsdata] = useState(false);

  const [servicesData, setServicesData] = useState([]);
  useEffect(() => {
    const title = sitedata?.site_title || "";
    document.title = capitalize(title) + " :: Services - ClinTa";
    window.scrollTo(0, 0);
    getServicesData();
  }, []);

  const getServicesData = async () => {
    const response = await SendPostRequest("services/list");
    if (!response?.data || response?.data?.length < 1) {
      setIsdata(true);
    } else {
      setServicesData(response.data);
    }
  };

  const [backgroundImage, setBackgroundImage] = useState("");
  useEffect(() => {
    let themeColor;
    if (sitedata) {
      themeColor = sitedata?.theme_color?.split(".css").join("");
    } else {
    }

    setBackgroundImage(`/assets/img/doctors/banner_bg_${themeColor}.svg`);
  }, [sitedata?.theme_color]);

  return (
    <>
      <Header />
      <section
        className="cs_banner cs_style_5 cs_bg_filed"
        // style={{
        //   backgroundImage:
        //     sitedata.site_type === "hospitals"
        //       ? "url('/assets/img/doctors/banner_bg.svg')"
        //       : "url('/assets/img/doctors/banner_bg_gold.svg')",
        // }}
        style={{ backgroundImage: `url(${backgroundImage})` }}
      >
        {/* <div className="cs_banner_img">
          <img src="/assets/img/doctors/banner_img.png" alt="Banner" />
        </div> */}
        <div className="container">
          <div className="cs_banner_text">
            <h2 className="cs_banner_title cs_fs_72">Our Services</h2>
          </div>
        </div>
      </section>
      <section>
        <div className="container mt-5">
          <div className="col-lg-12 col-md-12 col-xl-12">
            <div className="cs_section_heading cs_style_1">
              <h3 className="cs_section_subtitle text-uppercase cs_accent_color cs_semibold m-0 cs_accent_color cs_fs_32"></h3>
              <h2 className="cs_section_title cs_fs_15 m-0 w-100">
                Provides Our Best Services
              </h2>
            </div>
            <div className="cs_height_70 cs_height_lg_50"></div>
          </div>

          {isData ? (
            <div className="d-flex align-items-center justify-content-center flex-column">
              <img src="assets/img/search.png" alt="" />
              <h1 className="mt-5">Under Constuction</h1>
            </div>
          ) : (
            <div className="row">
              {servicesData?.length === 0 ? (
                <div
                  style={{ marginBottom: "100px" }}
                  className="d-flex align-items-center justify-content-center "
                >
                  <Spinner />
                </div>
              ) : (
                servicesData?.length > 0 &&
                servicesData?.map((service, index) => (
                  <div key={index} className="col-md-6 col-xl-4">
                    <div className="cs_iconbox cs_style_4 service-card-wrapper">
                      <div
                        className="cs_iconbox_icon cs_accent_bg rounded-circle cs_center services-img-wrapper"
                        style={{
                          backgroundImage: `url(${service?.service_image_url})`,
                        }}
                      ></div>
                      <h2 className="service-card-title">
                        {service.service_title}
                      </h2>
                      <div>
                        {/* <p className=" m-0">
                          {service.service_description.slice(0, 110)}
                        </p> */}

                        <div
                          dangerouslySetInnerHTML={{
                            __html: service.service_description.slice(0, 110),
                          }}
                        />
                        <Link
                          to={`/service/${service?.uid}/${service?.service_title
                            .replaceAll(" ", "-")
                            .replaceAll("/", "-")}`}
                          // to={`/service/${service?.service_slug}`}
                        >
                          <button className="service-read-more">
                            Read More
                          </button>
                        </Link>
                      </div>
                      {service.link && (
                        <a
                          href={service.link}
                          className="cs_iconbox_btn cs_center"
                        >
                          <img
                            src="/assets/img/icons/arrow_white.svg"
                            alt="Icon"
                          />
                          <img
                            src="/assets/img/icons/arrow_white.svg"
                            alt="Icon"
                          />
                        </a>
                      )}
                    </div>
                  </div>
                ))
              )}
            </div>
          )}
        </div>
      </section>
      <div className="cs_height_42 cs_height_xl_25"></div>
      <Footer />
    </>
  );
}
