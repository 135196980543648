import React, { useEffect, useState } from "react";
import $ from "jquery";
import Footer from "../Footer";
import Header from "../Header";
import { SendPostRequest } from "../../http";
import { useSelector } from "react-redux";
import { capitalize } from "@mui/material";

export default function About() {
  const sitedata = useSelector((store) => store.global.loginData);

  const [hostpitalAbout, setHostpitalAbout] = useState({});

  const [activeVisionIndex, setActiveVisionIndex] = useState(null);
  const [activeHistroyIndex, setActiveHistroyIndex] = useState(null);
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (section, index) => {
    switch (section) {
      case "vision":
        setActiveVisionIndex((prevIndex) =>
          prevIndex === index ? null : index
        );
        break;
      case "history":
        setActiveVisionIndex((prevIndex) =>
          prevIndex === index ? null : index
        );
        break;

      case "message":
        setActiveVisionIndex((prevIndex) =>
          prevIndex === index ? null : index
        );
        break;

      default:
        break;
    }
  };

  const sections = [
    {
      title: "Mission Vision Value",
      content: hostpitalAbout?.vision_mission,
      index: "vision",
    },
    {
      // title: "Bank Deposit/ Transfer",
      title: "History",
      content: hostpitalAbout?.history,
      index: "history",
    },
    {
      title: "CEO Messege",
      content: hostpitalAbout?.message,
      index: "message",
    },
  ];

  useEffect(() => {
    document.title = capitalize(sitedata?.site_title) + " :: About - ClinTa";
    window.scrollTo(0, 0);

    getHostpitalAboutData();
  }, []);

  // useEffect(() => {
  //   document.title = capitalize(sitedata?.site_title) + " :: About - ClinTa";
  //   window.scrollTo(0, 0);

  //   // Hide all accordion bodies initially
  //   $(".cs_accordian").children(".cs_accordian_body").hide();

  //   // Event handler for accordion header click
  //   $(".cs_accordian_head").on("click", function () {
  //     const $accordion = $(this).parent(".cs_accordian");
  //     const $body = $accordion.children(".cs_accordian_body");

  //     // Check if accordion is already active
  //     const isActive = $accordion.hasClass("active");

  //     if (isActive) {
  //       // If active, close the accordion
  //       $body.slideUp(250);
  //       $accordion.removeClass("active");
  //     } else {
  //       // If not active, open the accordion and close others
  //       $body.slideDown(250);
  //       $accordion.addClass("active");
  //       $accordion.siblings().children(".cs_accordian_body").slideUp(250);
  //       $accordion.siblings().removeClass("active");
  //     }
  //   });

  //   getHostpitalAboutData();
  // }, []);

  const getHostpitalAboutData = async () => {
    const response = await SendPostRequest("content/about");
    setHostpitalAbout(response.data);
  };

  const [backgroundImage, setBackgroundImage] = useState("");
  useEffect(() => {
    let themeColor;
    if (sitedata) {
      themeColor = sitedata?.theme_color?.split(".css").join("");
    } else {
    }

    setBackgroundImage(`/assets/img/doctors/banner_bg_${themeColor}.svg`);
  }, [sitedata?.theme_color]);
  return (
    <>
      <Header />
      <section
        className="cs_banner cs_style_5 cs_bg_filed"
        // style={{ backgroundImage: "url('/assets/img/doctors/banner_bg.svg')" }}
        style={{ backgroundImage: `url(${backgroundImage})` }}
      >
        {" "}
        {/* <div className="cs_banner_img">
          <img src="/assets/img/doctors/banner_img.png" alt="Banner" />
        </div> */}
        <div className="container">
          <div className="cs_banner_text">
            <h2 className="cs_banner_title cs_fs_72">About Us</h2>
          </div>
        </div>
      </section>

      <div className="cs_height_50 cs_height_lg_50"></div>
      <div className="container">
        <div className="cs_banner_text">
          <h2 className=" cs_fs_52 text-center">Introduction</h2>
          {/* <div
            className="cs_banner_subtitle cs_fs_12 mb-0 "
            style={{ textAlign: "center" }}
          >
            {hostpitalAbout?.intro
              ? hostpitalAbout?.intro
              : "Coming soon! Awaited content to be revealed shortly."}
          </div> */}

          <div
            className="cs_banner_subtitle cs_fs_12 mb-0"
            style={{ textAlign: "center" }}
          >
            {hostpitalAbout?.intro ? (
              <div
                dangerouslySetInnerHTML={{ __html: hostpitalAbout?.intro }}
              />
            ) : (
              "Coming soon! Awaited content to be revealed shortly."
            )}
          </div>
        </div>
      </div>
      <section>
        <div className="cs_height_100 cs_height_xl_105 cs_height_lg_105"></div>
        <div className="container">
          <div className="row">
            <div className="col-lg-5">
              <div className="cs_section_heading cs_style_1">
                <h3 className="cs_section_subtitle text-uppercase cs_accent_color cs_semibold m-0 cs_accent_color cs_fs_32"></h3>
                <div className="cs_height_5"></div>
                <h2 className="cs_section_title cs_fs_72 m-0">
                  <img
                    src={`${hostpitalAbout?.vision_mission_image}`}
                    alt="Banner"
                  />
                </h2>
              </div>
            </div>
            <div className="col-lg-7">
              <div className="cs_height_40 cs_height_lg_0"></div>

              <div class="cs_accordians cs_style1 cs_heading_color">
                {sections.map((section, index) => (
                  <div
                    className={`cs_accordian ${
                      index === activeVisionIndex ? "active" : ""
                    }`}
                    key={index}
                  >
                    <h2
                      className={`cs_accordian_head cs_heading_color ${
                        index === activeIndex ? "active" : ""
                      }`}
                      onClick={() => toggleAccordion(section.index, index)}
                    >
                      {section.title}
                      <span className="cs_accordian_arrow">
                        <svg
                          width="28"
                          height="28"
                          viewBox="0 0 28 28"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M-6.11959e-07 14C-2.74531e-07 21.7195 6.28053 28 14 28C21.7195 28 28 21.7195 28 14C28 6.28053 21.7195 -9.49388e-07 14 -6.11959e-07C6.28053 -2.74531e-07 -9.49388e-07 6.28053 -6.11959e-07 14ZM26.25 14C26.25 20.7548 20.7548 26.25 14 26.25C7.24522 26.25 1.75 20.7548 1.75 14C1.75 7.24522 7.24522 1.75 14 1.75C20.7548 1.75 26.25 7.24522 26.25 14ZM13.3814 8.13137C13.7233 7.78947 14.2769 7.78947 14.6186 8.13137L18.9936 12.5064C19.1645 12.6772 19.25 12.9012 19.25 13.125C19.25 13.3488 19.1645 13.5728 18.9936 13.7436C18.6517 14.0855 18.0981 14.0855 17.7564 13.7436L14.875 10.8622L14.875 19.25C14.875 19.7332 14.4837 20.125 14 20.125C13.5163 20.125 13.125 19.7332 13.125 19.25L13.125 10.8622L10.2436 13.7436C9.90172 14.0855 9.34806 14.0855 9.00637 13.7436C8.66469 13.4017 8.66447 12.8481 9.00637 12.5064L13.3814 8.13137Z"
                            fill="#307BC4"
                          />
                        </svg>
                      </span>
                    </h2>
                    <div
                      className="cs_accordian_body"
                      style={{
                        display: index === activeVisionIndex ? "block" : "none",
                      }}
                    >
                      <p>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: section?.content
                              ? section?.content
                              : "Coming soon! Awaited content to be revealed shortly.",
                          }}
                        />
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          {/* {hostpitalAbout?.message && hostpitalAbout?.message_image && (
            <>
              <div className="cs_height_50 cs_height_lg_50"></div>
              <h2 className=" cs_fs_52 text-center">CEO Messege</h2>
              <div className="cs_height_70 cs_height_lg_50"></div>
              <div className="row">
                <div className="col-lg-7 col-md-6 col-sm-12">
                  <p className="text-justify">{hostpitalAbout?.message}</p>
                </div>
                <div
                  className="col-lg-5 col-md-6 col-sm-12 "
                  style={{
                    backgroundImage: `url(data:image/jpeg;base64,${hostpitalAbout?.message_image})`,
                    backgroundPosition: "center center",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    borderRadius: "20px",
                  }}
                ></div>
              </div>
              <div className="cs_height_72"></div>
            </>
          )} */}
        </div>
      </section>
      <div className="cs_height_105"></div>
      <Footer />
    </>
  );
}
