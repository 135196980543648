import React, { useEffect, useState } from "react";
import { SendPostRequest } from "../http";
import { Link } from "react-router-dom";
import Spinner from "./Spinner";
import { formatDate } from "../Helper/Helper";

function HomeBlogs() {
  const [blogsData, setBlogsData] = useState([]);

  useEffect(() => {
    getBlogsData();
  }, []);

  const getBlogsData = async () => {
    const response = await SendPostRequest("blogs/list");
    setBlogsData(response?.data);
  };

  return (
    <div>
      {blogsData && blogsData?.length !== 0 && (
        <section>
          <div class="cs_height_160 cs_height_xl_110 cs_height_lg_70"></div>
          <div class="container">
            <div className="featured_services_heading_wrapper pb-5">
              <h2 className="cs_section_title cs_fs_65 m-0 ">Blog Posts</h2>
              <Link to="/blogs">
                <button className="featured_services_view_btn">View All</button>
              </Link>
            </div>
            <div class="cs_height_70 cs_height_lg_50"></div>
            {blogsData?.length === 0 ? (
              <div
                style={{ marginBottom: "100px" }}
                className="d-flex align-items-center justify-content-center "
              >
                <Spinner />
              </div>
            ) : (
              <div class="row cs_gap_y_24">
                <div class="col-lg-6">
                  <div class="cs_post_pr_136">
                    <div class="cs_post cs_style_1 cs_type_2">
                      <div class="cs_post_thumb cs_view_mouse blog_large_img">
                        <Link to={`/blog/detail/${blogsData[0]?.slug}`}>
                          <img src={blogsData[0]?.image} alt="Post" />
                        </Link>
                      </div>
                      <div class="cs_post_info">
                        <div>
                          <h2 class="cs_post_title cs_semibold cs_fs_32">
                            <Link to={`/blog/detail/${blogsData[0]?.slug}`}>
                              {blogsData[0]?.title}
                            </Link>
                          </h2>
                        </div>
                        <div class="author_detail_heading-wrapper w-100">
                          {blogsData[0]?.author_name ? (
                            <h3>
                              <Link
                                to={`/author/detail/${blogsData[0]?.author_slug}`}
                              >
                                Author: <span>{blogsData[0]?.author_name}</span>
                              </Link>
                            </h3>
                          ) : (
                            <h3>--</h3>
                          )}
                          <div class="cs_posted_by">
                            {formatDate(blogsData[0]?.date)}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-lg-6">
                  {blogsData?.slice(1, 4)?.map((data, index) => (
                    <div
                      key={index}
                      class="cs_post cs_style_2 cs_radius_20 overflow-hidden"
                    >
                      <div class="cs_post_thumb cs_view_mouse">
                        <Link to={`/author/detail/${data?.author_slug}`}>
                          <img src={data?.image} alt="Post" />
                        </Link>
                      </div>
                      <div class="cs_post_info w-100">
                        <div className="w-100">
                          <h2 class="cs_post_title cs_semibold cs_fs_24">
                            <Link to={`/blog/detail/${data?.slug}`}>
                              {data?.title}
                            </Link>
                          </h2>
                          <div class="author_detail_heading-wrapper w-100">
                            {data?.author_name ? (
                              <h3>
                                <Link
                                  to={`/author/detail/${data?.author_slug}`}
                                >
                                  Author: <span>{data?.author_name}</span>
                                </Link>
                              </h3>
                            ) : (
                              <h3>--</h3>
                            )}
                            <div class="cs_posted_by">
                              {formatDate(data?.date)}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}

                  {/* <div class="cs_post cs_style_2 cs_radius_20 overflow-hidden">
              <a href="blog-details.html" class="cs_post_thumb cs_view_mouse">
                <img src="assets/img/home_3/blog_3.jpg" alt="Post" />
              </a>
              <div class="cs_post_info">
                <div>
                  <h2 class="cs_post_title cs_semibold cs_fs_24">
                    <a href="blog-details.html">
                      The Importance of Regular Cancer Screenings and Early
                      Detection
                    </a>
                  </h2>
                  <div class="cs_posted_by">August 24, 2023</div>
                </div>
              </div>
            </div>
            <div class="cs_post cs_style_2 cs_radius_20 overflow-hidden">
              <a href="blog-details.html" class="cs_post_thumb cs_view_mouse">
                <img src="assets/img/home_3/blog_4.jpg" alt="Post" />
              </a>
              <div class="cs_post_info">
                <div>
                  <h2 class="cs_post_title cs_semibold cs_fs_24">
                    <a href="blog-details.html">
                      Managing Chronic Pain: Tips and Strategies for a Better
                      Quality of Life
                    </a>
                  </h2>
                  <div class="cs_posted_by">September 11, 2023</div>
                </div>
              </div>
            </div> */}
                </div>
              </div>
            )}
          </div>
        </section>
      )}
    </div>
  );
}

export default HomeBlogs;
