import React, { useState, useEffect } from "react";
import { MdDelete, MdEdit } from "react-icons/md";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { SendPostRequest } from "../http";
import { toast } from "react-toastify";
import { Spinner as SpinnerBoot } from "react-bootstrap";
import { formatDate, formatTime, today } from "../Helper/Helper";
import Spinner from "../components/Spinner";
import { useSelector } from "react-redux";

function BloodPressureList() {
  const patientData = useSelector((store) => store.patient.patientLogin);
  const [isLoading, setIsLoading] = useState(false);
  const [LoadingEdit, setLoadingEdit] = useState(false);
  const [LoadingDelete, setLoadingDelete] = useState(false);
  const [filterData, setFilterData] = useState(null);
  const [deleteId, setDeleteId] = useState(null);
  const [getData, setGetData] = useState([]);
  const [editData, setEditData] = useState({});
  const [showModal, setShowModal] = useState(false);

  const handleModalOpen = () => {
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async (filter) => {
    setIsLoading(true);
    try {
      const response = await SendPostRequest("healthcare/blood_pressure/grid", {
        logged_id: patientData?.logged_id,
        filter: filter || {},
      });
      if (response?.status === "success") {
        toast.success(response?.message);
        setGetData(response?.data);
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      console.error("error", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleOnChange = (e) => {
    const { value, name } = e.target;
    setEditData((prv) => ({ ...prv, [name]: value }));
  };

  const handleSelectChange = (e) => {
    setEditData((prv) => ({ ...prv, measurement_type: e.value }));
  };

  const handleEdit = async (e) => {
    e.preventDefault();
    const data = {
      logged_id: patientData?.logged_id,
      record_id: editData?.id,
      form_data: {
        systolic: editData?.systolic,
        diastolic: editData?.diastolic,
        pulse: editData?.pulse,
        record_date: editData?.record_date,
        record_time: editData?.record_time,
        notes: editData?.notes,
      },
    };
    setLoadingEdit(true);
    try {
      const response = await SendPostRequest(
        "healthcare/blood_pressure/edit",
        data
      );
      if (response?.status === "success") {
        toast.success(response?.message);
        handleModalClose();
        fetchData();
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      console.error("error", error);
    } finally {
      setLoadingEdit(false);
    }
  };
  const handleDelete = async (id) => {
    setDeleteId(id);
    const data = {
      logged_id: patientData?.logged_id,
      record_id: id,
    };
    setLoadingDelete(true);
    try {
      const response = await SendPostRequest(
        "healthcare/blood_pressure/delete",
        data
      );
      if (response?.status === "success") {
        toast.success(response?.message);
        fetchData();
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      console.error("error", error);
    } finally {
      setLoadingDelete(false);
    }
  };

  const handleFilterChange = (e) => {
    const { value, name } = e.target;
    setFilterData((prv) => ({ ...prv, [name]: value }));
  };

  const handleFilter = async (e) => {
    e.preventDefault();
    fetchData(filterData);
  };

  return (
    <>
      <div className="Prescripition">
        <div className="">
          <h3>Blood Pressure</h3>
        </div>

        <div className="weight-managment-card">
          <h6>SEARCH FILTERS</h6>
          <div class="alert alert-warning m-0 text-center" role="alert">
            This feature only available for Premium Members
          </div>
        </div>

        {isLoading ? (
          <div
            style={{ marginBottom: "60px" }}
            className="d-flex align-items-center justify-content-center "
          >
            <Spinner />
          </div>
        ) : (
          <div className="table-responsive weight-managment-card">
            <div className="d-flex align-items-center justify-content-between">
              <h6>Blood Pressure</h6>
              <form
                onSubmit={handleFilter}
                className="d-flex align-items-center justify-content-between gap-2"
              >
                <label htmlFor="from" className="p-1">
                  From:{" "}
                </label>
                <input
                  required
                  type="date"
                  name="date_from"
                  className="form-control"
                  value={filterData?.date_from || ""}
                  onChange={handleFilterChange}
                  max={today}
                />
                <label htmlFor="to" className="p-1">
                  To:{" "}
                </label>
                <input
                  required
                  type="date"
                  name="date_to"
                  className="form-control"
                  value={filterData?.date_to || ""}
                  onChange={handleFilterChange}
                  max={today}
                />
                <input
                  type="submit"
                  name="to"
                  className="form-control"
                  value="Filter"
                />
                <input
                  type="button"
                  name="clear_filter"
                  className="form-control"
                  value="Clear Filter"
                  onClick={() => {
                    setFilterData(null);
                    fetchData(null);
                  }}
                />
              </form>
            </div>
            <table class="table mt-2">
              <thead class="thead-light">
                <tr>
                  <th scope="col">SR.NO</th>
                  <th scope="col">Entry Dates</th>
                  <th scope="col">Record Dates</th>
                  <th scope="col">Record Time</th>
                  <th scope="col">Systolic</th>
                  <th scope="col"> Diastolic</th>
                  <th scope="col">Pulse</th>
                  <th scope="col">Note</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>

              <tbody>
                {getData.length === 0 ? (
                  <tr>
                    <td colSpan="9">
                      <div className="prescription-rapper">Data Not Found</div>
                    </td>
                  </tr>
                ) : (
                  getData.map((item, index) => (
                    <tr key={item?.uid}>
                      <td>{index + 1}</td>
                      <td>{formatDate(item?.entry_date)}</td>
                      <td>{formatDate(item?.record_date)}</td>
                      <td>{formatTime(item?.record_time)}</td>
                      <td>{item?.systolic}</td>
                      <td>{item?.diastolic}</td>
                      <td>{item?.pulse}</td>
                      <td>{item?.notes}</td>
                      <td>
                        <button
                          className="edit"
                          onClick={() => {
                            setEditData(item);
                            handleModalOpen();
                          }}
                        >
                          <MdEdit />
                        </button>
                        <button
                          className="delete"
                          onClick={() => handleDelete(item?.id)}
                          disabled={LoadingDelete}
                        >
                          {LoadingDelete && deleteId === item?.id ? (
                            <SpinnerBoot />
                          ) : (
                            <MdDelete />
                          )}
                        </button>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        )}
      </div>

      <Modal
        className="modal-wrapper"
        show={showModal}
        onHide={handleModalClose}
      >
        <form onSubmit={handleEdit}>
          <Modal.Header closeButton>
            <Modal.Title className="weight_management_modal-title">
              Blood Pressure
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-lg-6">
                <label className="cs_input_label cs_heading_color">
                  Systolic (Larger Number)
                </label>
                <input
                  required
                  type="text"
                  className="weight_management_modal_input"
                  placeholder="MmHg"
                  value={editData?.systolic}
                  name="systolic"
                  onChange={handleOnChange}
                />
                <div className="cs_height_42 cs_height_xl_25"></div>
              </div>

              <div className="col-lg-6">
                <label className="cs_input_label cs_heading_color">
                  Diastolic (Smaller Number)
                </label>
                <input
                  required
                  type="text"
                  className="weight_management_modal_input"
                  placeholder="MmHg"
                  value={editData?.diastolic}
                  name="diastolic"
                  onChange={handleOnChange}
                />
                <div className="cs_height_42 cs_height_xl_25"></div>
              </div>

              <div className="col-lg-12">
                <label className="cs_input_label cs_heading_color">
                  Pulse (Beats Per Minute)
                </label>
                <input
                  required
                  type="text"
                  className="weight_management_modal_input"
                  placeholder="Enter Your Pulse (Beats Per Minute)"
                  value={editData?.pulse}
                  name="pulse"
                  onChange={handleOnChange}
                />
                <div className="cs_height_42 cs_height_xl_25"></div>
              </div>

              <div className="col-lg-6">
                <label className="cs_input_label cs_heading_color">Date</label>
                <input
                  type="date"
                  className="weight_management_modal_input"
                  placeholder="Select Date"
                  value={editData?.record_date}
                  name="record_date"
                  onChange={handleOnChange}
                  max={today}
                />
                <div className="cs_height_42 cs_height_xl_25"></div>
              </div>

              <div className="col-lg-6">
                <label className="cs_input_label cs_heading_color">Time</label>
                <input
                  type="time"
                  className="weight_management_modal_input"
                  placeholder="Select Date"
                  value={editData?.record_time}
                  name="record_time"
                  onChange={handleOnChange}
                />
                <div className="cs_height_42 cs_height_xl_25"></div>
              </div>

              <div className="col-lg-12">
                <label className="cs_input_label cs_heading_color">Note</label>
                <input
                  type="textarea"
                  className="weight_management_modal_input"
                  placeholder="Enter Your Note"
                  value={editData?.notes}
                  name="notes"
                  onChange={handleOnChange}
                />
                <div className="cs_height_42 cs_height_xl_25"></div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="weight_management_submit-button"
              variant="secondary"
              type="submit"
              disabled={LoadingEdit}
            >
              {LoadingEdit ? "Submiting..." : "Submit"}
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
}

export default BloodPressureList;
