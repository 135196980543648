import React from "react";
import { Link } from "react-router-dom";
import "./Main.css";
import { useSelector } from "react-redux";

export default function HospitalNav() {
  const patientLogin = useSelector((state) => state.patient.patientLogin);
  const sitedata = useSelector((store) => store.global.loginData);

  return (
    <>
      <nav className="cs_nav ">
        <ul className="cs_nav_list small-screen-nav ">
          <li>
            <Link to="/">Home</Link>
          </li>
          {/* <li>
            <Link to="/about">About Us</Link>
          </li> */}
          <li className="menu-item-has-children">
            <Link to="#">About Us</Link>
            <ul>
              <li>
                <Link to="/about">Introduction</Link>
                {/* <Link to="/careers">Careers</Link> */}
              </li>
              {sitedata?.options?.patient_nav === "show" ? (
                <li>
                  <Link to="/reviews">Reviews</Link>
                </li>
              ) : (
                <li></li>
              )}

              <li>
                <Link to="/gallery">Gallery</Link>
              </li>
            </ul>
          </li>
          <li className="menu-item-has-children">
            <Link to="#">Patient Care</Link>
            <ul>
              <li>
                <Link to="/doctors" className="">
                  Doctors
                </Link>
              </li>
              <li>
                <Link to="/services">Services</Link>
              </li>
              <li>
                <Link to="/facilities">Facilities</Link>
              </li>
              <li>
                <Link to="/e_health">E Health</Link>
              </li>
            </ul>
          </li>

          <li className="menu-item-has-children">
            <Link to="#">Career</Link>
            <ul>
              <li>
                <Link to="/careers">Jobs</Link>
              </li>
              <li>
                <Link to="/drop-cv">Drop Your CV</Link>
              </li>
            </ul>
          </li>

          {/* <li>
            <Link to="/gallery">Gallery</Link>
          </li>
          <li>
            <Link to="/e_health">E Health</Link>
          </li> */}

          <li>
            <Link to="/complaints">Feedback</Link>
          </li>
          <li>
            <Link to="/contact">Contact Us</Link>
          </li>
          <li>
            {patientLogin ? (
              <Link to="/patient">
                <button className="login"> My Account</button>
              </Link>
            ) : (
              <Link to="/login">
                <button className="login">Login</button>
              </Link>
            )}
          </li>
        </ul>
      </nav>
    </>
  );
}
