import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Footer from "../Footer";
import Header from "../Header";
import { SendPostRequest } from "../../http";
import Spinner from "../Spinner";
import { useSelector } from "react-redux";
import { capitalize } from "@mui/material";
import { Button, Modal } from "react-bootstrap";

export default function Services() {
  const [facilitiesData, setFacilitiesData] = useState([]);
  const [isData, setIsData] = useState(false);
  const sitedata = useSelector((store) => store.global.loginData);

  useEffect(() => {
    const title = sitedata?.site_title || "";
    document.title = capitalize(title) + " :: Facilities - ClinTa";
    window.scrollTo(0, 1);
    getFacilitiesData();
  }, []);

  const getFacilitiesData = async () => {
    const response = await SendPostRequest("facilities");
    if (!response.data || response.data.length < 1) {
      setIsData(true);
    } else {
      setFacilitiesData(response.data);
    }
  };

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.slice(0, maxLength) + "...";
    }
    return text;
  };

  const [show, setShow] = useState(false);

  const [facilitiesIndex, setFacilitiesIndex] = useState();

  const handleClose = () => setShow(false);
  const handleShow = (index) => {
    setFacilitiesIndex(index);
    setShow(true);
  };

  const [backgroundImage, setBackgroundImage] = useState("");
  useEffect(() => {
    let themeColor;
    if (sitedata) {
      themeColor = sitedata?.theme_color?.split(".css").join("");
    } else {
    }

    setBackgroundImage(`/assets/img/doctors/banner_bg_${themeColor}.svg`);
  }, [sitedata?.theme_color]);
  return (
    <>
      <Header />
      <section
        className="cs_banner cs_style_5 cs_bg_filed"
        // style={{ backgroundImage: "url('/assets/img/doctors/banner_bg.svg')" }}
        style={{ backgroundImage: `url(${backgroundImage})` }}
      >
        {/* <div className="cs_banner_img">
          <img src="/assets/img/doctors/banner_img.png" alt="Banner" />
        </div> */}
        <div className="container">
          <div className="cs_banner_text">
            <h2 className="cs_banner_title cs_fs_72">Facilities</h2>
          </div>
        </div>
      </section>

      {isData ? (
        <div className="d-flex align-items-center justify-content-center flex-column">
          <div className="cs_height_96 cs_height_lg_70"></div>
          <img src="assets/img/search.png" alt="" />
          <h1 className="mt-5">Website Under Constuction</h1>
        </div>
      ) : (
        <div className="container">
          <div className="cs_height_96 cs_height_lg_70"></div>
          <div className="row cs_row_gap_50">
            {facilitiesData?.length === 0 ? (
              <div
                style={{ marginBottom: "100px" }}
                className="d-flex align-items-center justify-content-center "
              >
                <Spinner />
              </div>
            ) : (
              facilitiesData?.length > 0 &&
              facilitiesData?.map((data, index) => (
                <div key={index} className="col-lg-4 col-md-6 col-12">
                  <div className="cs_post cs_style_1">
                    {/* <Link className="cs_post_thumb">
                      <img
                        src={`data:image/jpeg;base64,${data?.facility_image}`}
                        alt="Post"
                      />
                    </Link> */}
                    <div
                      style={{
                        backgroundImage: `url(${data?.facility_image})`,
                        // backgroundImage: `url(data:image/jpeg;base64,${data?.facility_image})`,
                        backgroundPosition: "top center",
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        height: "250px",
                        width: "100%",
                        cursor: "pointer",
                      }}
                    />
                    <div className="cs_post_info">
                      <div>
                        <h2 className="cs_post_title cs_semibold cs_fs_32">
                          {data?.facility_title}
                        </h2>
                        {/* <p style={{ textAlign: "justify" }}>
                          {data?.facility_description &&
                            truncateText(data?.facility_description, 200)}
                        </p> */}
                        <p
                          style={{ textAlign: "justify" }}
                          dangerouslySetInnerHTML={{
                            __html:
                              data?.facility_description &&
                              truncateText(data?.facility_description, 200),
                          }}
                        />

                        <button onClick={() => handleShow(index)}>
                          Read More
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            )}

            <Modal show={show} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title className="review-modal-title">
                  {facilitiesData[facilitiesIndex]?.facility_title}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {/* {facilitiesData[facilitiesIndex]?.facility_description} */}
                <div
                  dangerouslySetInnerHTML={{
                    __html:
                      facilitiesData[facilitiesIndex]?.facility_description,
                  }}
                />
              </Modal.Body>
              <Modal.Footer>
                <Button
                  className="review-modal-button"
                  variant="secondary"
                  onClick={handleClose}
                >
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
      )}

      <div className="cs_height_96 cs_height_lg_70"></div>

      <Footer />
    </>
  );
}
