import React, { useEffect, useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import DepartmentCarousel from "./DepartmentCarousal/DepartmentCarousal";
import ReviewSlider from "./ReviewSlider/ReviewSlider";
import { SendPostRequest } from "../http";
import Spinner from "./Spinner";
import Slider from "react-slick";
import { useSelector } from "react-redux";
import { capitalize } from "@mui/material";
import CommingSoon from "./CommingSoon";
import MainBanner from "./MainBanner/MainBanner";
import SecondMainBanner from "./SecondMainBanner/SecondMainBanner";
import ThirdMainSlider from "./ThirdMainSlider/ThirdMainSlider";
import FourthMainBanner from "./FourthMainBanner/FourthMainBanner";
import { Button, Modal } from "react-bootstrap";
import HomeFacilities from "./HomeFacilities";
import HomeBlogs from "./HomeBlogs";
import HomeNews from "./HomeNews";
import DepartmentCarousalSlider from "./DepartmentCarousal/DepartmentCarousalSlider";

export default function Home({ isData, setIsdata }) {
  const sitedata = useSelector((store) => store.global.loginData);
  const [servicesData, setServicesData] = useState([]);
  const [brandingBanners, setBrandingBanners] = useState([]);
  const [hostpitalAbout, setHostpitalAbout] = useState({});
  const [expanded, setExpanded] = useState(false); // State to manage expanded/collapsed state
  const [truncateLength, setTruncateLength] = useState(1100);

  useEffect(() => {
    const title = sitedata?.site_title || "";
    document.title = capitalize(title) + " :: Home - ClinTa";
    getServicesData();
    brandingData();
    getClientReviews();
    getHostpitalAboutData();

    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setTruncateLength(400);
      } else {
        setTruncateLength(1100);
      }
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const getServicesData = async () => {
    const response = await SendPostRequest("services/list");
    setServicesData(response?.data);
  };

  const getHostpitalAboutData = async () => {
    const response = await SendPostRequest("content/about");
    setHostpitalAbout(response?.data);
  };

  const toggleExpandReview = (index) => {
    setExpandedIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const [expandedIndex, setExpandedIndex] = useState(null);
  const [selectedServices, setSelectedServices] = useState([]);

  useEffect(() => {
    const shuffleArray = (array) => {
      for (let i = array?.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
    };

    const shuffledServicesData = servicesData;
    shuffleArray(shuffledServicesData);
    setSelectedServices(shuffledServicesData?.slice(0, 6));
  }, [servicesData]);

  const brandingData = async () => {
    const response = await SendPostRequest("branding/banners");
    if (!response?.data || response?.data?.length < 1) {
      setIsdata(true);
    } else {
      setBrandingBanners(response?.data);
    }
  };

  const [clientReviews, setClientReviews] = useState([]);
  const [expandedReviews, setExpandedReviews] = useState([]);

  const getClientReviews = async () => {
    const response = await SendPostRequest("reviews");
    setClientReviews(response.data);
    setExpandedReviews(Array(response.data.length).fill(false));
  };

  const createMarkup = (html) => {
    return { __html: html };
  };

  const truncateText = (text, maxLength) => {
    if (text?.length > maxLength) {
      return text?.slice(0, maxLength) + "...";
    }
    return text;
  };

  const truncatedMessage = truncateText(
    hostpitalAbout?.message,
    truncateLength
  );

  return (
    <>
      {isData ? (
        <CommingSoon />
      ) : (
        <div>
          <Header />
          <div className="">
            {sitedata?.options?.banner_style === "animation" ? (
              <SecondMainBanner setIsdata={setIsdata} />
            ) : sitedata?.options?.banner_style === "version3" ? (
              <ThirdMainSlider setIsdata={setIsdata} />
            ) : sitedata?.options?.banner_style === "version4" ? (
              <FourthMainBanner setIsdata={setIsdata} />
            ) : (
              <MainBanner setIsdata={setIsdata} />
            )}
          </div>
          <div style={{ marginTop: "70px" }}>
            <DepartmentCarousel setIsdata={setIsdata} />
            {/* <DepartmentCarousalSlider setIsdata={setIsdata} /> */}
          </div>
          {sitedata?.site_type === "hospitals" && <HomeFacilities />}

          <div className="container">
            {hostpitalAbout?.message && hostpitalAbout?.message_image && (
              <>
                <div className="cs_height_100 cs_height_lg_100"></div>
                <h2 className="cs_fs_52 text-center">CEO Message</h2>
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                    <div
                      style={{
                        backgroundImage: `url(${hostpitalAbout?.message_image})`,
                        backgroundPosition: "center center",
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        borderRadius: "20px",
                        height: "500px",
                      }}
                    />
                  </div>

                  <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                    <div>
                      <span
                        dangerouslySetInnerHTML={createMarkup(
                          expanded ? hostpitalAbout?.message : truncatedMessage
                        )}
                      />
                      {/* <button
                        className="home_ceomessage_btn d-inline"
                        onClick={() => setExpanded(!expanded)}
                      >
                        {expanded ? "Read Less" : "Read More"}
                      </button> */}
                    </div>

                    <div className="home_ceomessage_btn">
                      <button onClick={() => setExpanded(!expanded)}>
                        {expanded ? "Read Less" : "Read More"}
                      </button>
                    </div>
                  </div>
                </div>
                <div className="cs_height_72"></div>
              </>
            )}
          </div>

          <HomeBlogs />
          <HomeNews />

          <div className="cs_height_185 cs_height_xl_140 cs_height_lg_100"></div>
          {sitedata?.options?.patient_nav === "show" &&
          clientReviews?.length > 0 ? (
            <section>
              <div className="">
                <div className="cs_section_heading cs_style_1 text-center">
                  <h3 className="cs_section_title cs_fs_72 m-0">
                    Patient's Reviews
                  </h3>
                </div>
                <div className="pb-5">
                  <ReviewSlider clientReviews={clientReviews} />
                </div>
              </div>
            </section>
          ) : (
            <div></div>
          )}
          <Footer />
        </div>
      )}
    </>
  );
}
