import React, { useState } from "react";
import { IoMdHeartEmpty, IoMdHeart } from "react-icons/io";
import Spinner from "../components/Spinner";
import { useSelector } from "react-redux";
import { SendPostRequest } from "../http";

function FruitsList({ fdata, handleFruitClick, fetch }) {
  const patientData = useSelector((store) => store.patient.patientLogin);
  const [loading, setLoading] = useState(false);
  const [checkedButtons, setCheckedButtons] = useState(
    Array(fdata?.length).fill(false)
  );

  const handleToggle = async (id, index, fav) => {
    setLoading(true);
    const response = await SendPostRequest("home/diet_items_fav", {
      item_id: id,
      logged_id: patientData?.logged_id,
      fav_action: !fav,
    });
    fetch();
    setLoading(false);
    // if (response?.status === "success") {
    //   alert(response?.message);
    // }

    // setCheckedButtons((prevCheckedButtons) => {
    //   const newCheckedButtons = [...prevCheckedButtons];
    //   newCheckedButtons[index] = !newCheckedButtons[index];
    //   return newCheckedButtons;
    // });
  };
  return (
    <>
      {fdata.length === 0 ? (
        <div
          style={{ marginBottom: "100px" }}
          className="d-flex align-items-center justify-content-center"
        >
          <Spinner />
        </div>
      ) : (
        fdata.map((data, i) => (
          <div className="col-lg-3 col-md-6 col-sm-12 mt-5">
            <div className="fruit fruit-diet-card">
              <div
                className=""
                onClick={() => handleFruitClick(data?.UID)}
                style={{
                  backgroundImage: `url(${data?.Image})`,
                  backgroundPosition: "center center",
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  height: "300px",
                  cursor: "pointer",
                }}
              ></div>
              <button
                disabled={loading}
                onClick={() => handleToggle(data?.UID, i, data?.Fav)}
              >
                {data?.Fav ? <IoMdHeart /> : <IoMdHeartEmpty />}
              </button>

              <div className="fruit-name">
                <p>{data?.Name}</p>
                <p>{data?.UrduName}</p>
              </div>
            </div>
          </div>
        ))
      )}
    </>
  );
}

export default FruitsList;
