// import ReactDOM from "react-dom/client";
import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Home from "./components/Home";
import Services from "./components/Services";
import Contact from "./components/Contact";
import Gallery from "./components/Gallery";
import Reviews from "./components/Reviews";
import ServicesList from "./components/Services_List";
import HospitalClinic from "./components/doctor/Hospital-Clinic";
import PatientPortal from "./components/doctor/Patient-Portal";
import Login from "./components/Login";
import DoctorDetails from "./components/hospital/Doctor-Details";
import Facilities from "./components/hospital/Facilities";
import Doctors from "./components/hospital/Doctors";
import Complaints from "./components/Complaints";
import Appointments from "./components/Appointments";
import { useDispatch } from "react-redux";
import { SendPostRequest } from "./http";
import { setLogindata1, setProfileCheck } from "./store/global/actions";
import LoginDashboard from "./components/HospitalLogin/LoginDashboard";
import E_health_Appointment from "./components/HospitalLogin/E_health_Appointment";
import BasicAppointment from "./components/HospitalLogin/Appointment";
import AboutContainer from "./components/AboutContainer";
import EHealthContainer from "./components/EHealthContainer";
import { useSelector } from "react-redux";
import Signup from "./components/Signup";
import ForgetPassword from "./components/ForgetPassword";
import FruitsDetail from "./DashboardComponents/FruitsDetail";
import Totkey from "./NewDashboardComponents/Totkey";
import Careers from "./components/hospital/Careers";
import CareerDetail from "./components/hospital/CareerDetail";
import DropCV from "./components/hospital/DropCV";
import BlogDetail from "./components/BlogDetail";
import Blogs from "./components/Blogs";
import NewsDetail from "./components/NewsDetail";
import News from "./components/News";
import AuthorDetail from "./components/AuthorDetail";

function App() {
  const [data, setData] = useState({});
  const [isData, setIsdata] = useState(false);
  const sitedata = useSelector((store) => store.global.loginData);

  // if (sitedata?.site_type == "hospitals") {
  //   import('./components/Main.css').then(() => {
  //   });
  // } else if (sitedata?.site_type == "doctors") {
  //   import('./components/Doctor.css').then(() => {
  //   });
  // }

  // useEffect(() => {
  //   if (sitedata) {
  //     document.title = sitedata?.site_title || "Welcome - ClinTa Web Builder";
  //     const favicon = document.querySelector("link[rel='icon']");
  //     if (favicon) {
  //       favicon.href = sitedata?.site_logo || "/assets/img/clinta.ico";
  //     }
  //   }
  // }, [sitedata]);

  const [faviconUrl, setFaviconUrl] = useState("");
  useEffect(() => {
    if (sitedata && sitedata?.site_logo) {
      const base64Url = `${sitedata?.site_logo}`;
      setFaviconUrl(base64Url);
    }
  }, [sitedata]);

  useEffect(() => {
    if (faviconUrl) {
      const faviconLink = document.querySelector("link[rel='icon']");
      if (faviconLink) {
        faviconLink.href = faviconUrl;
      } else {
        const newFaviconLink = document.createElement("link");
        newFaviconLink.rel = "icon";
        newFaviconLink.href = faviconUrl;
        document.head.appendChild(newFaviconLink);
      }
    }
  }, [faviconUrl]);

  const themeColor = sitedata?.theme_color;

  if (themeColor) {
    import(`./components/${themeColor}`)
      .then((module) => {
        console.log("Module loaded:", module);
      })
      .catch((error) => {
        console.error("Failed to load module:", error);
      });
  } else {
    console.error("theme_color is undefined or null");
  }

  // import("./components/" + sitedata?.theme_color).then(() => {});
  // import("./components/red.css").then(() => {});
  // import("./components/black.css").then(() => {});
  // import("./components/gold.css").then(() => {});

  const dispatch = useDispatch();
  const patientLogin = useSelector((state) => state.patient.patientLogin);

  useEffect(() => {
    getData();
    loginCheckData();
  }, []);

  const getData = async () => {
    const res = await SendPostRequest("branding/info");
    setData(res?.data);
    if (res?.data) {
      localStorage.setItem("login_type", res?.data?.site_type);
      dispatch(setLogindata1(res.data));
    }
  };

  const [loginCheck, setLoginCheck] = useState({});
  const loginCheckData = async () => {
    const res = await SendPostRequest("home/profile_check");
    setLoginCheck(res?.data[0]);
    dispatch(setProfileCheck(res?.data[0]));
  };

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={<Home isData={isData} setIsdata={setIsdata} />}
          />

          <Route path="/services" element={<Services />} />

          <Route path="/doctors" element={<Doctors />} />

          <Route path="/gallery" element={<Gallery />} />
          <Route path="/careers" element={<Careers />} />
          <Route path="/career-detail/:id" element={<CareerDetail />} />
          <Route path="/drop-cv" element={<DropCV />} />

          <Route path="/about" element={<AboutContainer />} />

          <Route path="/facilities" element={<Facilities />} />

          <Route path="forget-password/:slug" element={<ForgetPassword />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/appointment" element={<Appointments />} />
          <Route path="/complaints" element={<Complaints />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/e_health" element={<EHealthContainer />} />
          <Route path="/reviews" element={<Reviews />} />
          <Route path="/doctor/:id/:slug" element={<DoctorDetails />} />
          {patientLogin ? (
            <Route path="patient/*" element={<LoginDashboard />} />
          ) : (
            <Route path="patient/*" element={<Navigate to="/" />} />
          )}
          <Route path="/patient-portal" element={<PatientPortal />} />
          <Route path="/hospital-clinic/:slug" element={<HospitalClinic />} />
          <Route path="/login" element={<Login />} />
          <Route
            path="/patient/e_health_appointment"
            element={<E_health_Appointment />}
          />
          <Route path="/patient/appointments" element={<BasicAppointment />} />
          {/* <Route path="/service/:slug" element={<ServicesList />} /> */}
          <Route path="/service/:id/:slug" element={<ServicesList />} />
          <Route path="fruit-detail" element={<FruitsDetail />} />
          <Route path="totkey" element={<Totkey />} />
          <Route path="/blogs" element={<Blogs />} />
          <Route path="/news" element={<News />} />
          <Route path="/blog/detail/:slug" element={<BlogDetail />} />
          <Route path="/author/detail/:slug" element={<AuthorDetail/>} />
          <Route path="/news/detail/:slug" element={<NewsDetail />} />
        </Routes>
      </BrowserRouter>

      {isData ? (
        <div></div>
      ) : sitedata?.site_type === "hospitals" ? (
        <a href="/appointment">
          <button className="clickme-appointment">Appointment</button>
        </a>
      ) : (
        <a href="/hospital-clinic/0">
          <button className="clickme-appointment">Appointment</button>
        </a>
      )}

      {/* <a href="/drop-cv">
        <button className="clickme-cv-btn">Drop Your CV</button>
      </a> */}
    </>
  );
}

export default App;
