import React, { useEffect, useState } from "react";
import { SendPostRequest } from "../http";
import Spinner from "../components/Spinner";
import { IoMdHeart, IoMdHeartEmpty } from "react-icons/io";
import { Spinner as SpinnerBoot } from "react-bootstrap";
import { useSelector } from "react-redux";

function FruitsDetail({ fruitId }) {
  const patientData = useSelector((store) => store.patient.patientLogin);
  const [loading, setLoading] = useState(false);
  const [majorCategory, setMajorCategory] = useState("");
  const [singleFruitData, setSingleFruitData] = useState();

  const handleToggle = async (id, fav) => {
    setLoading(true);
    const response = await SendPostRequest("home/diet_items_fav", {
      item_id: id,
      logged_id: patientData?.logged_id,
      fav_action: !fav,
    });
    setLoading(false);
    if (response?.status === "success") {
      // alert(response?.message);
      fetchSingleFruit();
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    fetchSingleFruit();
  }, [fruitId]);

  const fetchSingleFruit = async () => {
    try {
      const response = await SendPostRequest("home/diet_items", {
        item_id: fruitId,
        logged_id: patientData?.logged_id,
      });
      setSingleFruitData(response?.data);

      if (response.status === "success") {
      } else {
        console.error("Error fetching fruits:", response?.message);
      }
    } catch (error) {
      console.error("Error fetching fruits:", error);
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await SendPostRequest("home/diet_categories", {
          host: "drburhan.clinta.biz",
          type: "full",
        });
        setMajorCategory(response?.data?.category);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
      }
    };

    fetchData();
  }, []);

  return (
    <>
      {majorCategory?.length === 0 ? (
        <div
          style={{ marginBottom: "100px" }}
          className="d-flex align-items-center justify-content-center"
        >
          <Spinner />
        </div>
      ) : (
        <div className="ComparativeNutrients">
          <div className="row mb-5">
            <div
              className="col-lg-3"
              style={{
                backgroundImage: `url(${singleFruitData?.item_Detail?.Image})`,
                backgroundPosition: "center center",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                height: "200px",
              }}
            >
              {/* <img src={singleFruitData?.item_Detail?.Image} alt="Fruit Image" /> */}
            </div>
            <div className="col-lg-9">
              <div className="d-flex justify-content-between">
                <h4 style={{ textTransform: "capitalize" }}>
                  {singleFruitData?.item_Detail?.Category} -{" "}
                  {singleFruitData?.item_Detail?.Name} -{" "}
                  {singleFruitData?.item_Detail?.UrduName}
                </h4>
                <div
                  onClick={() =>
                    handleToggle(
                      singleFruitData?.item_Detail?.UID,
                      singleFruitData?.item_Detail?.Fav
                    )
                  }
                  className="fs-2 cur-pointer"
                >
                  {loading ? (
                    <SpinnerBoot />
                  ) : singleFruitData?.item_Detail?.Fav ? (
                    <IoMdHeart className="heart_icon" />
                  ) : (
                    <IoMdHeartEmpty className="heart_icon" />
                  )}
                </div>
              </div>

              <hr />
              <p>{singleFruitData?.item_Detail?.Description}</p>
            </div>
          </div>

          <div className="items">NUTRITIONAL ITEMS</div>

          {singleFruitData &&
            Object.entries(singleFruitData?.diet_fact_detail).map(
              ([categoryKey, categoryValue], key) => (
                <table class="table">
                  <thead>
                    <tr>
                      <th colspan="4">{categoryKey.toUpperCase()}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {categoryValue.map((item, key) => (
                      <tr key={key}>
                        <td style={{ width: "50%" }}>
                          {item.title.toUpperCase()}
                        </td>
                        <td className="text-center">
                          {item.val} {item.val ? item.unit : "--"}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )
            )}
        </div>
      )}
    </>
  );
}

export default FruitsDetail;
