import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./DepartmentCarousal.css";
import { SendPostRequest } from "../../http";
import { useSelector } from "react-redux";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        background: "#FFF",
        borderRadius: "10px",
        padding: "12px 35px 35px 35px",
      }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        background: "#FFF",
        borderRadius: "10px",
        padding: "12px 35px 35px 35px",
      }}
      onClick={onClick}
    />
  );
}

const DepartmentCarousalSlider = ({ setIsdata }) => {
  const [featuredService, setFeaturedService] = useState([]);
  const sitedata = useSelector((store) => store.global.loginData);
  useEffect(() => {
    document.title = "Home";
    getFeaturedService();
  }, []);

  const getFeaturedService = async () => {
    const response = await SendPostRequest("services/featured_services");
    // if (response?.data.length < 5) {
    //   setIsdata(true)
    // }
    if (response?.data.length < 5 && sitedata?.site_type === "hospitals") {
      setIsdata(true);
    }
    setFeaturedService(response?.data);
  };

  const departmentData = [
    {
      title: "Emergency Department",
      icon: "/assets/img/home_1/department_icon_1.svg",
    },
    {
      title: "Pediatric Department",
      icon: "/assets/img/home_1/department_icon_2.svg",
    },
    {
      title: "Gynecology Department",
      icon: "/assets/img/home_1/department_icon_3.svg",
    },
    {
      title: "Cardiology Department",
      icon: "/assets/img/home_1/department_icon_4.svg",
    },
    {
      title: "Neurology Department",
      icon: "/assets/img/home_1/department_icon_5.svg",
    },
    {
      title: "Psychiatry Department",
      icon: "/assets/img/home_1/department_icon_6.svg",
    },
  ];

  const slickSettings = {
    // nextArrow: <SampleNextArrow />,
    // prevArrow: <SamplePrevArrow />,
    slidesToShow: 5,
    infinite: true,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 3000,
    dots: false,
    arrows: false,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className="container department-carousal">
      <div className="cs_departments cs_style_1">
        <div
          className="cs_departments_bg cs_radius_25 department-carousal-bg"
          style={{}}
          // data-src="/assets/img/home_1/department_bg.svg"
        ></div>
        <div className="cs_section_heading cs_style_1 text-center">
          <h2 className="cs_section_title cs_fs_72 m-0 cs_white_color">
            Featured Services
          </h2>
        </div>
        <div className="cs_height_72 cs_height_lg_50"></div>

        {/* for desktop */}

        <div className="feature-services">
          {featuredService?.length > 0 &&
            featuredService?.map((item, index) => (
              <div key={index} className="service">
                <div
                  style={{
                    backgroundImage: `url(${item?.service_image_url})`,
                    backgroundPosition: "center center",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    borderRadius: "50%",
                    height: "100px",
                    width: "100px",
                  }}
                ></div>
                <p className=" ">{item?.service_title}</p>
              </div>
            ))}
        </div>

        <Slider className="d-block" {...slickSettings}>
          {featuredService?.length > 0 &&
            featuredService?.map((item, index) => (
              <div key={index} className="cs_slide">
                <a className="cs_department cs_department-carousal-cards cs_shadow_1 cs_radius_20">
                  <div
                    style={{
                      backgroundImage: `url(${item?.service_image_url})`,
                      backgroundPosition: "center center",
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                      borderRadius: "50%",
                      height: "100px",
                      width: "100px",
                    }}
                  ></div>
                  <p className="cs_department_title cs_medium cs_heading_color cs_fs_20 mb-0 ">
                    {item?.service_title}
                  </p>
                </a>
              </div>
            ))}
        </Slider>
      </div>
    </div>
  );
};

export default DepartmentCarousalSlider;
