import React, { useState } from "react";
import { IoMdHeartEmpty, IoMdHeart } from "react-icons/io";
import Spinner from "../components/Spinner";

function FruitFilter({
  responseData,
  fdata,
  handleFruitClick,
  isSubmitLoading,
}) {
  const [checkedButtons, setCheckedButtons] = useState(
    Array(fdata?.length).fill(false)
  );

  const handleToggle = (index) => {
    setCheckedButtons((prevCheckedButtons) => {
      const newCheckedButtons = [...prevCheckedButtons];
      newCheckedButtons[index] = !newCheckedButtons[index];
      return newCheckedButtons;
    });
  };
  return (
    <>
      {isSubmitLoading ? (
        <div
          style={{ marginBottom: "100px" }}
          className="d-flex align-items-center justify-content-center"
        >
          <Spinner />
        </div>
      ) : (
        responseData?.item_detail?.map((data, i) => (
          <div className="col-lg-4 col-md-6 col-sm-12 mt-5">
            <div className="fruit fruit-diet-card">
              <div
                onClick={() => handleFruitClick(data?.id)}
                className=""
                style={{
                  backgroundImage: `url(${data?.image})`,
                  backgroundPosition: "center center",
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  height: "300px",
                  cursor: "pointer",
                }}
              ></div>
              <button onClick={() => handleToggle(i)}>
                {checkedButtons[i] ? <IoMdHeart /> : <IoMdHeartEmpty />}
              </button>

              <div className="fruit-name">
                <p>{data?.name}</p>
                <p>{data?.urduName}</p>
              </div>
              <div className="fruit-name">
                <p>{data?.nutrition}</p>
                <p>
                  {data?.value} {data?.unit}
                </p>
              </div>
            </div>
          </div>
        ))
      )}
    </>
  );
}

export default FruitFilter;
