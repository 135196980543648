import React, { useState, useEffect, useRef } from "react";
import Footer from "./Footer";
import Header from "./Header";
import { SendPostRequest } from "../http";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Captcha from "./Captcha/Captcha";
import { useSelector } from "react-redux";

export default function Complaints() {
  const sitedata = useSelector((store) => store.global.loginData);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [complaintData, setComplaintData] = useState({
    full_name: null,
    mrn: "",
    email: "",
    contact_number: "",
    complaint_detail: "",
    complaint_attachment_1: null,
    complaint_attachment_2: null,
  });

  function capitalize(str) {
    return str.replace(/\b\w/g, (char) => char.toUpperCase());
  }

  useEffect(() => {
    const title = sitedata?.site_title || "";
    document.title = capitalize(title) + " :: Complaints - ClinTa";
    window.scrollTo(0, 0);
    handleRefresh();
  }, []);

  const handlePostData = (e) => {
    let { value, name } = e.target;
    setComplaintData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    let name = e.target.name;
    let file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      const base64String = reader.result.split(",")[1];
      setComplaintData((prevData) => ({
        ...prevData,
        [name]: base64String,
      }));
    };
    reader.readAsDataURL(file);
  };

  const [captcha, setCaptcha] = useState("");
  const [input_captcha, setInputCaptcha] = useState("");
  const [check_captcha, setCheckCaptcha] = useState("");
  const formRef = useRef(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!complaintData.full_name) {
      toast.error("Enter Your Name");
      return;
    } else if (!complaintData.contact_number) {
      toast.error("InComplete Form");
      return;
    } else if (
      complaintData.contact_number.length > 14 ||
      complaintData.contact_number.length < 10
    ) {
      toast.error("Invalid Contact Number");
      return;
    } else if (!input_captcha) {
      toast.error("Please Enter Captcha");
      return;
    } else if (captcha !== input_captcha) {
      toast.error("Invalid Captcha");
      return;
    } else if (!complaintData.complaint_detail) {
      toast.error("InComplete Form");
      return;
    }

    if (captcha === input_captcha) {
      setCheckCaptcha("Captcha Verified");
      try {
        setIsSubmitting(true);
        const response = await SendPostRequest("complaints/add", complaintData);
        formRef.current.reset();

        if (response.status === "success") {
          setComplaintData({
            full_name: null,
            mrn: "",
            email: "",
            contact_number: "",
            complaint_detail: "",
            complaint_attachment_1: null,
            complaint_attachment_2: null,
          });
          handleRefresh();
          setInputCaptcha(null);
          setCheckCaptcha(null);

          toast.success("Complaints Submitted Successfully");
        } else {
          let errorMessage = response.message || "An error occurred";
          toast.error(`Error: ${errorMessage}`);
          console.error("Error fetching complaints data:", errorMessage);
        }
      } catch (error) {
        toast.error(`Error: ${error.message}`);
        console.error("Error fetching complaints data:", error);
      } finally {
        setIsSubmitting(false);
      }
    } else {
      setCheckCaptcha("Captcha failed");
      toast.error("Captcha failed");
      return;
    }
  };

  const textColorClass =
    check_captcha === "Captcha Verified" ? "text-success" : "text-danger";

  const handleInputChange = (e) => {
    setInputCaptcha(e.target.value);
  };

  const handleRefresh = () => {
    const newCaptcha = generateRandomCaptcha();
    setCaptcha(newCaptcha);
  };

  const generateRandomCaptcha = () => {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const captchaLength = 6;
    let newCaptcha = "";

    for (let i = 0; i < captchaLength; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      newCaptcha += characters.charAt(randomIndex);
    }

    return newCaptcha;
  };

  const [backgroundImage, setBackgroundImage] = useState("");
  useEffect(() => {
    let themeColor;
    if (sitedata) {
      themeColor = sitedata?.theme_color?.split(".css").join("");
    } else {
    }

    setBackgroundImage(`/assets/img/doctors/banner_bg_${themeColor}.svg`);
  }, [sitedata?.theme_color]);
  return (
    <>
      <Header />
      <ToastContainer />
      <section
        className="cs_banner cs_style_5 cs_bg_filed"
        // style={{ backgroundImage: "url(/assets/img/contact/banner_bg.svg)" }}

        style={{ backgroundImage: `url(${backgroundImage})` }}
      >
        <div className="cs_banner_img"></div>
        <div className="container">
          <h2 className="cs_banner_title cs_fs_72">Feedback</h2>
        </div>
      </section>
      <section>
        <div className="container cs_mt_minus_110 complaints-form-wrapper">
          <div className="row">
            <div className="col-lg-10 offset-lg-1">
              <form ref={formRef} onSubmit={handleSubmit}>
                <div className="cs_contact_form cs_style_1 cs_white_bg cs_radius_30">
                  <div className="row">
                    <div className="col-lg-6">
                      <label className="cs_input_label cs_heading_color">
                        Full Name <span className="asterisk">*</span>
                      </label>
                      <input
                        type="text"
                        className="cs_form_field"
                        name="full_name"
                        value={complaintData.full_name || ""}
                        onChange={handlePostData}
                        placeholder="Enter full name"
                      />
                      <div className="cs_height_42 cs_height_xl_25"></div>
                    </div>
                    <div className="col-lg-6">
                      <label className="cs_input_label cs_heading_color">
                        MRN
                      </label>
                      <input
                        type="text"
                        className="cs_form_field"
                        name="mrn"
                        value={complaintData.mrn || ""}
                        onChange={handlePostData}
                        placeholder="Enter MRN number"
                      />
                      <div className="cs_height_42 cs_height_xl_25"></div>
                    </div>
                    <div className="col-lg-6">
                      <label className="cs_input_label cs_heading_color">
                        Email
                      </label>
                      <input
                        type="email"
                        className="cs_form_field"
                        name="email"
                        value={complaintData.email || ""}
                        onChange={handlePostData}
                        placeholder="Enter email"
                      />
                      <div className="cs_height_42 cs_height_xl_25"></div>
                    </div>
                    <div className="col-lg-6">
                      <label className="cs_input_label cs_heading_color">
                        Contact No <span className="asterisk">*</span>
                      </label>
                      <input
                        type="text"
                        className="cs_form_field"
                        name="contact_number"
                        value={complaintData.contact_number || ""}
                        onChange={handlePostData}
                        placeholder="Enter phone number"
                        maxLength={14}
                        minLength={10}
                      />
                      <div className="cs_height_42 cs_height_xl_25"></div>
                    </div>
                    <div className="col-lg-12">
                      <Captcha
                        captcha={captcha}
                        inputCaptcha={input_captcha}
                        textColorClass={textColorClass}
                        checkCaptcha={check_captcha}
                        handleInputChange={handleInputChange}
                      />
                    </div>
                    <div className="col-lg-12">
                      <label className="cs_input_label cs_heading_color">
                        Feedback <span className="asterisk">*</span>
                      </label>
                      <textarea
                        cols="30"
                        rows="5"
                        className="cs_form_field"
                        placeholder="Enter your feedback..."
                        name="complaint_detail"
                        value={complaintData.complaint_detail || ""}
                        onChange={handlePostData}
                      ></textarea>
                      <div className="cs_height_42 cs_height_xl_25"></div>
                    </div>
                    <div className="col-lg-6">
                      <label className="cs_input_label cs_heading_color">
                        Attachment 1
                      </label>
                      <input
                        type="file"
                        className="cs_form_field"
                        placeholder="example@gmail.com"
                        name="complaint_attachment_1"
                        onChange={handleFileChange}
                        accept="image/*"
                      />
                      <div className="cs_height_42 cs_height_xl_25"></div>
                    </div>
                    <div className="col-lg-6">
                      <label className="cs_input_label cs_heading_color">
                        Attachment 2
                      </label>
                      <input
                        type="file"
                        className="cs_form_field"
                        placeholder="example@gmail.com"
                        name="complaint_attachment_2"
                        value={Complaints.complaint_attachment_2}
                        file={null}
                        onChange={handleFileChange}
                        accept="image/*"
                      />
                      <div className="cs_height_42 cs_height_xl_25"></div>
                    </div>

                    <div className="col-lg-12">
                      <div className="cs_height_18"></div>
                      <button type="submit" className="cs_btn cs_style_1">
                        <span>Submit</span>
                        <i>
                          <img
                            src="/assets/img/icons/arrow_white.svg"
                            alt="Icon"
                          />
                          <img
                            src="/assets/img/icons/arrow_white.svg"
                            alt="Icon"
                          />
                        </i>
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      <div className="cs_height_42 cs_height_xl_25"></div>
      <Footer />
    </>
  );
}
