import React, { useEffect, useState } from "react";
import { SendPostRequest } from "../http";
import Spinner from "../components/Spinner";
import { Accordion, Spinner as SpinnerBoot } from "react-bootstrap";
import { IoMdHeart, IoMdHeartEmpty } from "react-icons/io";
import { useSelector } from "react-redux";

function Totkey() {
  const patientData = useSelector((store) => store.patient.patientLogin);
  const [totkeyList, setTotkeyList] = useState([]);
  const [totkeyItem, setTotkeyItem] = useState([]);
  const [selectedTotkey, setSelectedTotkey] = useState("");
  const [loadingList, setLoadingList] = useState(false);
  const [loadingItem, setLoadingItem] = useState(false);
  const [loadingFav, setLoadingFav] = useState(null);

  const handleToggle = async (id, fav) => {
    setLoadingFav(id);
    const response = await SendPostRequest("home/totkey_fav", {
      totkey_id: id,
      // logged_id: '663',
      logged_id: patientData?.logged_id,
      fav_action: !fav,
    });
    setLoadingFav(null);
    if (response?.status === "success") {
      getTotkeyItem();
    }
  };

  const getTotkeyList = async () => {
    setLoadingList(true);
    const response = await SendPostRequest("healthcare/totkey-categories");
    setTotkeyList(response?.data);
    setLoadingList(false);

    // After fetching the list, select the first Totkey
    if (response?.data?.length > 0) {
      setSelectedTotkey(response.data[0]?.id);
    }
  };

  const getTotkeyItem = async () => {
    setLoadingItem(true);
    const response = await SendPostRequest("healthcare/totkey-items", {
      category: selectedTotkey,
      logged_id: patientData?.logged_id,
      // logged_id: '663',
    });
    setTotkeyItem(response?.data);
    setLoadingItem(false);
  };

  useEffect(() => {
    getTotkeyList();
  }, []);

  useEffect(() => {
    if (selectedTotkey) {
      getTotkeyItem();
    }
  }, [selectedTotkey]);

  return (
    <div className="container">
      <div className="row">
        <div className="col-12 col-lg-7">
          <div className="bg-body-secondary p-3 d-flex flex-column gap-2 rounded-3">
            {loadingItem ? (
              <div className="text-center">
                <Spinner />
              </div>
            ) : (
              <Accordion defaultActiveKey="0" flush>
                {totkeyItem.length === 0 ? (
                  <div className="text-center">No Totkey item found!</div>
                ) : (
                  totkeyItem?.map((value, i) => (
                    <Accordion.Item eventKey={i} key={i}>
                      <Accordion.Header className="mb-0">
                        <div className="d-flex justify-content-between w-100 pr-3">
                          <span
                            disabled={loadingFav}
                            onClick={() => handleToggle(value?.id, value?.Fav)}
                            className="fs-4"
                          >
                            {loadingFav && loadingFav === value?.id ? (
                              <SpinnerBoot />
                            ) : value?.Fav ? (
                              <IoMdHeart className="heart_icon" />
                            ) : (
                              <IoMdHeartEmpty className="heart_icon" />
                            )}
                          </span>
                          <span className="fs-5 text-end">
                            {value?.heading}
                          </span>
                        </div>
                      </Accordion.Header>
                      <Accordion.Body>
                        <div
                          className="text-right fs-5"
                          dangerouslySetInnerHTML={{
                            __html: value?.description,
                          }}
                        ></div>
                      </Accordion.Body>
                    </Accordion.Item>
                  ))
                )}
              </Accordion>
            )}
          </div>
        </div>
        <div className="col-12 col-lg-5">
          <div className="bg-body-secondary p-3 d-flex flex-column gap-2 rounded-3">
            {loadingList ? (
              <div className="text-center">
                <Spinner />
              </div>
            ) : (
              <>
                {totkeyList?.map((value, i) => (
                  <div
                    key={i}
                    className={`p-2  text-right cur-pointer ${
                      selectedTotkey === value?.id
                        ? "text-light bg-theme "
                        : "text-dark bg-primary-subtle"
                    }`}
                    onClick={() => setSelectedTotkey(value?.id)}
                  >
                    <span className="float-end pl-1">{" -" + value?.id}</span>
                    <span className="fs-5">{value?.name}</span>{" "}
                  </div>
                ))}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Totkey;
