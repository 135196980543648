import React, { useState, useEffect } from "react";
import { FaDownload } from "react-icons/fa6";
import { SendPostRequest } from "../http";
import Spinner from "../components/Spinner";
import { Spinner as SpinnerBoot } from "react-bootstrap";
import { useSelector } from "react-redux";

function Prescripition() {
  const [isLoading, setIsLoading] = useState(false);
  const [prescriptionData, setPrescriptionData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [uniqueDoctors, setUniqueDoctors] = useState([]);
  const [selectedDoctor, setSelectedDoctor] = useState("all");
  const patientLogin = useSelector((state) => state.patient.patientLogin);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    fetchData("healthcare/prescriptions");
  }, []);

  const fetchData = async (endpoint) => {
    setIsLoading(true);
    try {
      const res = await SendPostRequest(endpoint, {
        user_id: patientLogin?.contact_num,
      });
      setUniqueDoctors([...new Set(res?.data.map((doc) => doc?.Doctor))]);
      setPrescriptionData(res?.data);
      setFilteredData(res?.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleReportDownload = (reportUrl) => {
    window.open(reportUrl, "_blank");
  };

  useEffect(() => {
    if (selectedDoctor === "all") {
      setFilteredData(prescriptionData);
    } else {
      setFilteredData(
        prescriptionData.filter((doctor) => doctor?.Doctor === selectedDoctor)
      );
    }
  }, [selectedDoctor]);

  return (
    <div className="Prescripition">
      <div className="prescripition-heading-wrapper">
        <h3>Prescription</h3>
        <div className="prescripition-button-wrapper">
          <button
            className="login"
            onClick={() => fetchData("healthcare/prescriptions")}
          >
            OPD
          </button>
          <button
            className="login"
            onClick={() => fetchData("healthcare/emr_prescriptions")}
          >
            EMERGENCY
          </button>
        </div>
      </div>
      <div className="text-end mt-2">
        {isLoading ? (
          <div className="d-flex align-items-center justify-content-center ">
            <SpinnerBoot />
          </div>
        ) : (
          <select
            className="text-capitalize prescrioption_filters"
            onClick={(e) => setSelectedDoctor(e.target.value)}
            name="filterPatient"
            id="filterPatient"
            placeholder="Select a Patient"
          >
            <option className="" value="all">
              all
            </option>
            {uniqueDoctors?.map((item, index) => (
              <option value={item} key={index}>
                {item}
              </option>
            ))}
          </select>
        )}
      </div>

      {isLoading ? (
        <div
          style={{ marginBottom: "60px" }}
          className="d-flex align-items-center justify-content-center"
        >
          <Spinner />
        </div>
      ) : (
        <div className="table-responsive">
          <table className="table mt-2">
            <thead className="thead-light">
              <tr>
                <th scope="col">SR.NO</th>
                <th scope="col">DOCTOR</th>
                <th scope="col">DATE</th>
                <th scope="col">COMPLAINTS</th>
                <th scope="col">DIAGNOSIS</th>
                <th scope="col">ACTION</th>
              </tr>
            </thead>
            <tbody>
              {filteredData?.length === 0 ? (
                <tr>
                  <td colSpan="6">
                    <div className="prescription-rapper">Data Not Found</div>
                  </td>
                </tr>
              ) : (
                filteredData?.map((prescription, index) => (
                  <tr key={prescription?.PrescriptionUID}>
                    <td>{index + 1}</td>
                    <td>{prescription?.Doctor}</td>
                    <td style={{ width: "100px" }}>
                      {prescription?.AppointmentDateTime}
                    </td>
                    <td style={{ width: "500px" }}>
                      {prescription?.complaint}
                    </td>
                    <td style={{ width: "500px" }}>
                      {prescription?.notes || "--"}
                    </td>
                    <td>
                      <button
                        className="download"
                        onClick={() => handleReportDownload(prescription?.link)}
                      >
                        <FaDownload />
                      </button>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

export default Prescripition;
