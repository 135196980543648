import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Select from "react-select";
import Footer from "../Footer";
import Header from "../Header";
import { SendPostRequest } from "../../http";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { BsClockHistory, BsChatDots } from "react-icons/bs";
import { HiOutlinePhoneMissedCall } from "react-icons/hi";
import { FiFacebook } from "react-icons/fi";
import Captcha from "../Captcha/Captcha";
import axios, { all } from "axios";
import { toast } from "react-toastify";
import { capitalize } from "@mui/material";
import { formatTime } from "../../Helper/Helper";

export default function DoctorDetails() {
  const sitedata = useSelector((store) => store.global.loginData);
  const patientLogin = useSelector((state) => state.patient.patientLogin);
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (patientLogin) {
      setInputValues({
        patient_name: patientLogin?.fullname,
        email: patientLogin?.email,
        contact_number: patientLogin?.contact_num,
      });
    }

    const title = sitedata?.site_title || "";
    document.title = capitalize(title) + " :: Hospital Details - ClinTa";

    window.scrollTo(0, 0);
    getHospitalDetail();
    handleRefresh();
  }, []);

  const [hospital_detail, sethospitalDetail] = useState([]);
  const [selectedHospital, setSelectedHospital] = useState(null);
  const [gender, setGender] = useState(null);
  const [shift, setShift] = useState(null);

  const todayDate = new Date().toISOString().split("T")[0];

  const [relation, setRelation] = useState(null);
  const [selectedDep, setSelectedDep] = useState(null);

  const [inputValues, setInputValues] = useState({});
  const [hospitalSlots, setHospitalSlots] = useState([]);
  const [displayedValue, setDisplayedValue] = useState(null);
  const [bookingDay, setBookingDay] = useState("");

  const [appointmentisLoading, setAppointmentisLoading] = useState(false);

  const formatDate = (inputDate) => {
    const dateObject = new Date(inputDate);

    const year = dateObject.getFullYear();
    const month = dateObject.getMonth() + 1;
    const day = dateObject.getDate();
    const formattedDate = `${year}-${month < 10 ? "0" + month : month}-${
      day < 10 ? "0" + day : day
    }`;

    return formattedDate;
  };

  // get hospital slots
  useEffect(() => {
    const getHospitalDetail = async () => {
      const date = inputValues.appointment_date;
      const type = sitedata.site_type;
      let allInputs;

      try {
        allInputs = { type, doct_uid: id, date };
        if (date) {
          const res = await SendPostRequest("hospitals/slots", allInputs);
          setHospitalSlots(res.data);
        }
      } catch (error) {
        console.error("Error fetching doctor's slots:", error);
      }
    };
    getHospitalDetail();
  }, [inputValues.appointment_date]);

  const handleContactNumber = (e) => {
    let val = e.target.value;

    if (val.length <= 14) {
      setInputValues((prevValues) => ({
        ...prevValues,
        contact_number: val,
      }));
    }
    return;
  };

  const handleAllInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "appointment_date") {
      const formattedDate = formatDate(value);
      const selectedDate = new Date(formattedDate);
      const dayOfWeek = selectedDate.toLocaleDateString("en-US", {
        weekday: "long",
      }); // Get the day of the week as a string (e.g., "Monday")
      setBookingDay(dayOfWeek);
      setInputValues((prevValues) => ({
        ...prevValues,
        appointment_date: formattedDate,
      }));
    } else {
      setInputValues((prevValues) => ({
        ...prevValues,
        [name]: value,
      }));
    }
  };
  const [apointmentDays, setApointmentDays] = useState([]);

  const getHospitalDetail = async () => {
    try {
      const response = await SendPostRequest("hospitals/schedule", {
        doct_uid: id,
      });

      if (response.data) {
        const hospitals = response.data.hospitals;
        sethospitalDetail(hospitals);
        setApointmentDays(hospitals[0].schedule);

        if (hospitals.length > 0) {
          setSelectedHospital({
            label: hospitals[0].info.title,
            value: hospitals[0],
          });
        }
      } else {
        if (response.length === 1) {
          sethospitalDetail(response);
          setSelectedHospital({
            label: response[0].info.title,
            value: response[0],
          });
        }
      }
    } catch (error) {
      console.error("Error fetching doctor's schedule:", error);
    }
  };

  const handleHospitalChange = (selectedOption) => {
    setSelectedHospital(selectedOption);
  };

  const reasonOptions = [
    { value: "son-of", label: "Son Of" },
    { value: "daughter-of", label: "Daughter Of" },
    { value: "father-of", label: "Father Of" },
    { value: "mother-of", label: "Mother Of" },
    { value: "husband-of", label: "Husband Of" },
    { value: "wife-of", label: "Wife Of" },
  ];
  const depOptions = [
    { value: "routine-checkup", label: "Routine Checkup" },
    { value: "Operation", label: "Operation" },
    { value: "pathology-test", label: "Pathology Test" },
    { value: "report-checkup", label: "Report Checkup" },
    { value: "other-reason", label: "Other Reason" },
  ];

  const handleDepChange = (selectedOption) => {
    setSelectedDep(selectedOption);
  };

  const hospitalSchedules = {
    CMHHospital: [
      { day: "Monday", time: "09.00-12.00" },
      { day: "Wednesday", time: "15.00-18.00" },
      { day: "Friday", time: "09.00-12.00" },
    ],
    AlShifaHospital: [
      { day: "Monday", time: "10.00-13.00" },
      { day: "Tuesday", time: "14.00-17.00" },
      { day: "Thursday", time: "09.00-12.00" },
    ],
  };
  const hospitalOptions = [
    { value: "AlShifaHospital", label: "AlShifaHospital" },
    { value: "CMHHospital", label: "CMHHospital" },
  ];

  const genderOptions = [
    { value: "male", label: "Male" },
    { value: "female", label: "Female" },
  ];

  const [shiftOptions, setShiftOptions] = useState([]);

  useEffect(() => {
    const newShiftOptions = [];

    // Check if hospitalSlots is empty
    if (
      Object.keys(hospitalSlots).length === 0 &&
      inputValues.appointment_date
    ) {
      // If empty, set default values
      if (bookingDay !== "") {
        const selectedDayObject = apointmentDays[bookingDay];
        const Morning = "Morning";
        const Evening = "Evening";
        if (selectedDayObject[Morning] && selectedDayObject[Evening]) {
          newShiftOptions.push({ value: "evening", label: "Evening" });
          newShiftOptions.push({ value: "morning", label: "Morning" });
        } else if (selectedDayObject[Morning]) {
          newShiftOptions.push({ value: "morning", label: "Morning" });
        } else if (selectedDayObject[Evening]) {
          newShiftOptions.push({ value: "evening", label: "Evening" });
        }
      }
    } else {
      // If not empty, populate shift options from hospitalSlots
      for (const key in hospitalSlots) {
        if (hospitalSlots.hasOwnProperty(key)) {
          newShiftOptions.push({ value: key, label: hospitalSlots[key] });
        }
      }
    }

    setShiftOptions(newShiftOptions);
    setDisplayedValue(null);
  }, [hospitalSlots]);

  const handleReasonChange = (selectedOption) => {
    setRelation(selectedOption.value);
  };

  const handleGenderChange = (selectedOption) => {
    setGender(selectedOption.value);
  };

  const handleShiftChange = (selectedOption) => {
    setShift(selectedOption.value);
    setDisplayedValue(selectedOption?.label);
  };

  const [captcha, setCaptcha] = useState("");
  const [input_captcha, setInputCaptcha] = useState("");
  const [check_captcha, setCheckCaptcha] = useState("");

  const textColorClass =
    check_captcha === "Captcha Verified" ? "text-success" : "text-danger";

  const handleInputChange = (e) => {
    setInputCaptcha(e.target.value);
  };

  const handleRefresh = () => {
    const newCaptcha = generateRandomCaptcha();
    setCaptcha(newCaptcha);
  };

  const generateRandomCaptcha = () => {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const captchaLength = 6;
    let newCaptcha = "";

    for (let i = 0; i < captchaLength; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      newCaptcha += characters.charAt(randomIndex);
    }

    return newCaptcha;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setAppointmentisLoading(true);

    if (!inputValues.patient_name) {
      toast.error("Enter patient name");
      setAppointmentisLoading(false);
      return;
    } else if (!gender) {
      toast.error("Select Gender");
      setAppointmentisLoading(false);
      return;
    } else if (
      inputValues.email &&
      (!inputValues.email.includes(".") || !inputValues.email.includes("@"))
    ) {
      toast.error("Invalid email");
      setAppointmentisLoading(false);
      return;
    } else if (
      inputValues.contact_number.length > 14 ||
      inputValues.contact_number.length < 10
    ) {
      toast.error("Invalid Number");
      setAppointmentisLoading(false);
      return;
    } else if (!inputValues.contact_number) {
      toast.error("Enter Number");
      setAppointmentisLoading(false);
      return;
    } else if (!inputValues.appointment_date) {
      toast.error("Enter Date");
      setAppointmentisLoading(false);
      return;
    } else if (!shift) {
      toast.error("Select Your Shift");
      setAppointmentisLoading(false);
      return;
    } else if (!input_captcha) {
      toast.error("Enter Captcha");
      setAppointmentisLoading(false);
      return;
    } else if (captcha === input_captcha) {
      setCheckCaptcha("Captcha Verified");
    } else {
      setCheckCaptcha("Captcha failed");
      setAppointmentisLoading(false);
    }

    if (captcha == input_captcha) {
      setCheckCaptcha("Captcha Verified");
      try {
        const myallInputs = {
          ...inputValues,
          gender,
          shift,
          relation,
          doctor_id: id,
        };

        const response = await SendPostRequest(
          "doctors/appointment_form",
          myallInputs
        );

        if (response.status === "success") {
          setInputValues({
            patient_name: patientLogin ? patientLogin.fullname : "",
            email: patientLogin ? patientLogin.email : "",
            contact_number: patientLogin ? patientLogin.contact_num : "",
            age: "",
            relation_name: "",
            appointment_date: "",
            remarks: "",
          });
          setDisplayedValue(null);
          handleRefresh();
          setInputCaptcha("");
          setCheckCaptcha("");
          setRelation({ value: "" });
          setGender({ value: "" });
          setShift({ value: "" });
        }

        toast.success(response.message);
        setAppointmentisLoading(false);
      } catch (error) {
        toast.error("Failed");
        setAppointmentisLoading(false);
      }
    } else {
      setCheckCaptcha("Captcha failed");
      setAppointmentisLoading(false);
    }
  };

  const [backgroundImage, setBackgroundImage] = useState("");
  useEffect(() => {
    // const themeColor = sitedata?.theme_color.replace(".css", "");
    let themeColor;
    if (sitedata) {
      themeColor = sitedata?.theme_color?.split(".css").join("");
    } else {
    }

    setBackgroundImage(`/assets/img/doctors/banner_bg_${themeColor}.svg`);
  }, [sitedata?.theme_color]);

  return (
    <>
      <Header />
      <section
        className="cs_banner cs_style_5 cs_bg_filed"
        // style={{ backgroundImage: "url('/assets/img/doctors/banner_bg.svg')" }}
        style={{ backgroundImage: `url(${backgroundImage})` }}
      >
        {/* <div className="cs_banner_img">
          <img src="/assets/img/doctors/banner_img.png" alt="Banner" />
        </div> */}
        <div className="container">
          <div className="cs_banner_text">
            <h2 className="cs_banner_title cs_fs_72">
              {" "}
              {hospital_detail[0]?.info
                ? hospital_detail[0]?.info?.name
                : "Doctor Detail"}
            </h2>
          </div>
        </div>
      </section>

      <section className="doctor-detail-section mt-5">
        <div className="cs_height_100 cs_height_xl_80 cs_height_lg_100"></div>
        <div className="container">
          <div className="cs_related_doctor position-relative">
            <div className="cs_slider_activate">
              <div className="cs_slide">
                <div className="cs_doctor_profile cs_style_1">
                  <div
                    className="cs_doctor_img"
                    style={{
                      // backgroundImage: `url(data:image/jpeg;base64,${hospital_detail[0]?.info?.profile_pic})`,
                      backgroundImage: `url(${hospital_detail[0]?.info?.profile_pic})`,
                      backgroundSize: "cover",
                      backgroundPosition: "top center",
                    }}
                  ></div>
                  <div className="cs_doctor_profile_right position-absolute doctor-profile-detail">
                    <div className="cs_doctor_info cs_radius_25 cs_white_bg overflow-hidden">
                      <div className="cs_doctor_info_in">
                        <h3 className="cs_fs_40 cs_semibold mb-1">
                          {/* {hospital_detail[0]?.info
                            ? hospital_detail[0]?.info?.name
                            : "Coming soon! Awaited content to be revealed shortly."} */}
                        </h3>
                        <div className="d-flex justify-content-between align-item-center">
                          <h4 className="cs_fs_38 cs_semibold mb-1">
                            {hospital_detail[0]?.info?.department}
                          </h4>
                          <h4 className="cs_fs_38 cs_semibold mb-1">
                            {/* {hospital_detail[0]?.info?.fee} */}
                            <div
                              dangerouslySetInnerHTML={{
                                __html: hospital_detail[0]?.info?.fee,
                              }}
                            />
                          </h4>
                        </div>

                        <p className="cs_fs_20 cs_heading_color mb-0">
                          {hospital_detail[0]?.info?.qualification}
                        </p>
                        <div className="cs_height_9"></div>
                        <p className="mb-0 doctor-detail-description">
                          {hospital_detail[0]?.info?.short_description}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="cs_height_50"></div>

      <section
        className="cs_appointment_section_1 cs_bg_filed appointent-doc-section position-relative"
        data-src="assets/img/home_2/appointment_bg.svg"
      >
        <div className="container doctor-detail-hospital-clinic">
          <div className="row mt-5">
            <div className=" col-lg-6 col-md-12 col-12">
              <div className="cs_section_heading cs_style_1">
                <h3 className="cs_section_subtitle text-uppercase cs_accent_color cs_semibold m-0 cs_accent_color cs_fs_32">
                  BOOK AN
                </h3>
                <div className="cs_height_5"></div>
                <h2 className="cs_section_title doctor-main-heading m-0">
                  Appointment
                </h2>
              </div>
              <div className="cs_height_57"></div>
              <form className="row">
                <div className="col-lg-6">
                  <label className="cs_input_label cs_heading_color">
                    Patient Name <span className="asterisk">*</span>
                  </label>
                  <input
                    name="patient_name"
                    type="text"
                    className="cs_form_field"
                    value={inputValues.patient_name}
                    placeholder="Enter Patient Name"
                    onChange={handleAllInputChange}
                  />
                  <div className="cs_height_42 cs_height_xl_25"></div>
                </div>
                <div className="col-lg-6">
                  <label className="cs_input_label cs_heading_color">
                    Please Select Gender <span className="asterisk">*</span>
                  </label>
                  <Select
                    // name="gender"
                    className="gallery-select-field"
                    value={gender?.value}
                    options={genderOptions}
                    onChange={handleGenderChange}
                    placeholder="Select Gender"
                  />

                  <div className="cs_height_42 cs_height_xl_25"></div>
                </div>
                <div className="col-lg-6">
                  <label className="cs_input_label cs_heading_color">
                    Please Select Relation
                  </label>
                  <Select
                    className="gallery-select-field"
                    value={relation?.value}
                    options={reasonOptions}
                    onChange={handleReasonChange}
                    placeholder="Select Relation"
                  />

                  <div className="cs_height_42 cs_height_xl_25"></div>
                </div>
                <div className="col-lg-6">
                  <label className="cs_input_label cs_heading_color">
                    Relation Name
                  </label>
                  <input
                    name="relation_name"
                    value={inputValues.relation_name}
                    type="text"
                    className="cs_form_field"
                    placeholder="Enter Relation Name"
                    onChange={handleAllInputChange}
                  />
                  <div className="cs_height_42 cs_height_xl_25"></div>
                </div>

                <div className="col-lg-6">
                  <label className="cs_input_label cs_heading_color">
                    Email
                  </label>
                  <input
                    name="email"
                    value={inputValues.email}
                    type="email"
                    className="cs_form_field"
                    placeholder="Enter Your Email"
                    onChange={handleAllInputChange}
                  />
                  <div className="cs_height_42 cs_height_xl_25"></div>
                </div>
                <div className="col-lg-6">
                  <label className="cs_input_label cs_heading_color">
                    Contact Number <span className="asterisk">*</span>
                  </label>
                  <input
                    name="contact_number"
                    value={inputValues.contact_number}
                    type="text"
                    className="cs_form_field"
                    placeholder="Enter Contact Number"
                    onChange={handleContactNumber}
                    maxLength={14}
                    minLength={10}
                  />
                  <div className="cs_height_42 cs_height_xl_25"></div>
                </div>

                <div className="col-lg-6">
                  <label className="cs_input_label cs_heading_color">
                    Preferred Date <span className="asterisk">*</span>
                  </label>
                  <div className="cs_with_icon_input">
                    <input
                      name="appointment_date"
                      value={inputValues.appointment_date}
                      type="date"
                      className="cs_form_field"
                      id="datepicker"
                      placeholder="August 24, 2023"
                      onChange={handleAllInputChange}
                      min={todayDate}
                    />
                  </div>
                  <div className="cs_height_42 cs_height_xl_25"></div>
                </div>

                <div className="col-lg-6">
                  <label className="cs_input_label cs_heading_color">
                    Preferred Time <span className="asterisk">*</span>
                  </label>
                  <Select
                    className="gallery-select-field"
                    value={
                      displayedValue !== null
                        ? { value: hospitalSlots?.value, label: displayedValue }
                        : null
                    }
                    options={shiftOptions}
                    onChange={handleShiftChange}
                    placeholder={
                      inputValues.appointment_date
                        ? "Select Time"
                        : "Please Select Date First"
                    }
                  />

                  <div className="cs_height_42 cs_height_xl_25"></div>
                </div>

                <div className="col-lg-6">
                  <label className="cs_input_label cs_heading_color">Age</label>
                  <input
                    name="age"
                    value={inputValues.age}
                    type="text"
                    className="cs_form_field"
                    placeholder="Enter Your Age"
                    onChange={handleAllInputChange}
                  />
                  <div className="cs_height_42 cs_height_xl_25"></div>
                </div>

                <div className="col-lg-6">
                  <Captcha
                    captcha={captcha}
                    inputCaptcha={input_captcha}
                    textColorClass={textColorClass}
                    checkCaptcha={check_captcha}
                    handleInputChange={handleInputChange}
                  />
                </div>

                <div className="col-lg-12">
                  <label className="cs_input_label cs_heading_color">
                    Remarks
                  </label>
                  <textarea
                    rows={5}
                    name="remarks"
                    type="number"
                    value={inputValues.remarks}
                    className="cs_form_field pt-3"
                    placeholder="Write Your Remarks Here........."
                    onChange={handleAllInputChange}
                  ></textarea>
                  <div className="cs_height_45 cs_height_xl_25"></div>
                </div>

                <div className="col-lg-12">
                  <div className="cs_height_18"></div>
                  <button
                    className="cs_btn cs_style_1"
                    type="submit"
                    onClick={handleSubmit}
                    disabled={appointmentisLoading}
                  >
                    <span>
                      {" "}
                      {appointmentisLoading ? "Please Wait" : "Submit "}
                    </span>
                    <i>
                      <img src="/assets/img/icons/arrow_white.svg" alt="Icon" />
                      <img src="/assets/img/icons/arrow_white.svg" alt="Icon" />
                    </i>
                  </button>
                </div>
              </form>
            </div>
            <div className="col-lg-6 col-md-12 col-12">
              <h1 className="appointment-heading">Appointment Schedules</h1>

              <div className="hospital-select-field">
                {hospital_detail?.length > 1 && (
                  <Select
                    value={selectedHospital}
                    options={hospital_detail.map((hospital) => ({
                      label: hospital.info.title,
                      value: hospital,
                    }))}
                    onChange={handleHospitalChange}
                    placeholder="Select Hospital"
                    className="mt-5 pt-3"
                  />
                )}

                <div className="col-lg-12 appointment-schedule pl-0 pr-0">
                  <table className="table rounded-table">
                    <thead>
                      <tr>
                        <th>Day</th>
                        <th>Morning</th>
                        <th>Evening</th>
                      </tr>
                    </thead>
                    <tbody>
                      {!selectedHospital?.value.schedule ||
                      selectedHospital?.value.schedule?.length === 0 ? (
                        <tr>
                          <td colspan="3">
                            <img src="/assets/img/search.png" alt="data" />
                            <h1 className="text-center mt-3">
                              Website Under Construction
                            </h1>
                          </td>
                        </tr>
                      ) : (
                        Object.keys(selectedHospital?.value.schedule).map(
                          (day) => (
                            <tr key={day}>
                              <td>{day}</td>
                              <td>
                                {selectedHospital.value.schedule[day]
                                  ?.Morning ? (
                                  <span>
                                    <i className="fa-regular fa-clock"></i>{" "}
                                    {selectedHospital.value.schedule[day]
                                      .Morning.Start === "On Call" ||
                                    selectedHospital.value.schedule[day].Morning
                                      .End === "On Call"
                                      ? "On Call"
                                      : `${formatTime(
                                          selectedHospital.value.schedule[day]
                                            .Morning.Start
                                        )} - ${formatTime(
                                          selectedHospital.value.schedule[day]
                                            .Morning.End
                                        )}`}
                                  </span>
                                ) : (
                                  <span>
                                    <i className="fa-regular fa-clock mr-1"></i>{" "}
                                    N/A
                                  </span>
                                )}
                              </td>
                              <td>
                                {selectedHospital.value.schedule[day]
                                  ?.Evening ? (
                                  <span>
                                    <i className="fa-regular fa-clock"></i>{" "}
                                    {selectedHospital.value.schedule[day]
                                      .Evening.Start === "On Call" ||
                                    selectedHospital.value.schedule[day].Evening
                                      .End === "On Call"
                                      ? "On Call"
                                      : `${formatTime(
                                          selectedHospital.value.schedule[day]
                                            .Evening.Start
                                        )} - ${formatTime(
                                          selectedHospital.value.schedule[day]
                                            .Evening.End
                                        )}`}
                                  </span>
                                ) : (
                                  <span>
                                    <i className="fa-regular fa-clock mr-1"></i>{" "}
                                    N/A
                                  </span>
                                )}
                              </td>
                            </tr>
                          )
                        )
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="cs_height_120"></div>
        </div>
      </section>
      <Footer />
    </>
  );
}
