import React, { useEffect, useState } from "react";
import { SendPostRequest } from "../http";
import { Link } from "react-router-dom";
import Spinner from "./Spinner";
import Footer from "./Footer";
import Header from "./Header";
import { useSelector } from "react-redux";
import { formatDate } from "../Helper/Helper";

function Blogs() {
  const sitedata = useSelector((store) => store.global.loginData);
  const [blogsData, setBlogsData] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    getBlogsData();
  }, []);

  const getBlogsData = async () => {
    const response = await SendPostRequest("blogs/list");
    setBlogsData(response?.data);
  };

  const [backgroundImage, setBackgroundImage] = useState("");
  useEffect(() => {
    let themeColor;
    if (sitedata) {
      themeColor = sitedata?.theme_color?.split(".css").join("");
    } else {
    }

    setBackgroundImage(`/assets/img/doctors/banner_bg_${themeColor}.svg`);
  }, [sitedata?.theme_color]);

  return (
    <>
      <Header />
      <section
        className="cs_banner cs_style_5 cs_bg_filed"
        style={{ backgroundImage: `url(${backgroundImage})` }}
      >
        <div className="container">
          <div className="cs_banner_text">
            <h2 className="cs_banner_title cs_fs_72">Our Blogs</h2>
          </div>
        </div>
      </section>
      <section>
        <div className="cs_height_190 cs_height_xl_145 cs_height_lg_105"></div>
        <div className="container">
          <div className="cs_section_heading cs_style_1 text-center">
            <h3 className="cs_section_subtitle text-uppercase cs_accent_color cs_semibold m-0 cs_accent_color cs_fs_32">
              BLOG POSTS
            </h3>
            <div className="cs_height_5"></div>
            <h2 className="cs_section_title cs_fs_72 m-0">Latest Update</h2>
          </div>
          <div className="cs_height_72 cs_height_lg_50"></div>
          <div className="row gy-4">
            {blogsData?.map((data, index) => (
              <div key={index} className="col-lg-4">
                <div className="cs_post cs_style_1">
                  <Link className="cs_post_thumb cs_view_mouse blog_card_img">
                    <img src={data?.image} alt="Post" />
                  </Link>
                  <div className="cs_post_info blogs_card_rapping">
                    <div>
                      <div class="cs_post_meta author_detail_heading-wrapper">
                        <h3>
                          <Link to={`/author/detail/${data?.author_slug}`}>
                            Author: <span>{data?.author_name || "--"}</span>
                          </Link>
                        </h3>
                        <div class="cs_posted_by">{formatDate(data?.date)}</div>
                      </div>
                      <h2 className="cs_post_title cs_semibold cs_fs_32">
                        <Link to={`/blog/detail/${data?.slug}`}>
                          {data?.title}
                        </Link>
                      </h2>
                    </div>
                    {/* <div className="cs_heading_color cs_medium">
                      <a href="blog-details.html" className="cs_post_btn">
                        Learn More
                      </a>
                    </div> */}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      <div className="cs_height_42 cs_height_xl_25"></div>
      <Footer />
    </>
  );
}

export default Blogs;
