import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { SendPostRequest } from "../http";
import Spinner from "./Spinner";
import { Link } from "react-router-dom";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function HomeFacilities() {
  const [facilitiesData, setFacilitiesData] = useState([]);
  const [isData, setIsData] = useState(false);

  const random = Math.round(Math.random() * facilitiesData.length);
  useEffect(() => {
    getFacilitiesData();
  }, []);

  const getFacilitiesData = async () => {
    const response = await SendPostRequest("facilities");
    if (!response.data || response.data.length < 1) {
      setIsData(true);
    } else {
      setFacilitiesData(response.data);
    }
  };

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.slice(0, maxLength) + "...";
    }
    return text;
  };

  const [show, setShow] = useState(false);

  const [facilitiesIndex, setFacilitiesIndex] = useState();

  const handleClose = () => setShow(false);
  const handleShow = (index) => {
    setFacilitiesIndex(index);
    setShow(true);
  };

  const settings = {
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: true,
    // autoplay:true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="container">
      <div className="cs_height_96 cs_height_lg_70"></div>

      <div className="featured_services_heading_wrapper mb-5">
        <h2 className="cs_section_title cs_fs_65 m-0 ">Facilities</h2>
        <Link to="/facilities">
          <button className="featured_services_view_btn">View All</button>
        </Link>
      </div>

      <div className="featured_services_desktop">
        <div className="row cs_row_gap_50">
          {facilitiesData?.length === 0 ? (
            <div
              style={{ marginBottom: "100px" }}
              className="d-flex align-items-center justify-content-center "
            >
              <Spinner />
            </div>
          ) : (
            facilitiesData?.length > 0 &&
            facilitiesData
              ?.slice(
                random >= 3 ? random - 3 : random,
                random < 3 ? random + 3 : random
              )
              ?.map((data, index) => (
                <div key={index} className="col-lg-4 col-md-6 col-12">
                  <div className="cs_post cs_style_1 facility-card">
                    <div
                      style={{
                        backgroundImage: `url(${data?.facility_image})`,
                        backgroundPosition: "top center",
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        height: "250px",
                        width: "100%",
                        cursor: "pointer",
                      }}
                    />
                    <div className="cs_post_info">
                      <div className="facilities-home">
                        <h2 className="cs_post_title cs_semibold cs_fs_32">
                          {data?.facility_title}
                        </h2>
                        <p
                          dangerouslySetInnerHTML={{
                            __html:
                              data?.facility_description &&
                              truncateText(data?.facility_description, 130),
                          }}
                        />

                        <button onClick={() => handleShow(index)}>
                          Read More
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ))
          )}
        </div>
      </div>

      <div className="featured_services_mobile">
      <Slider {...settings}>
      {facilitiesData?.length === 0 ? (
            <div
              style={{ marginBottom: "100px" }}
              className="d-flex align-items-center justify-content-center "
            >
              <Spinner />
            </div>
          ) : (
            facilitiesData?.length > 0 &&
            facilitiesData
              ?.slice(
                random >= 3 ? random - 3 : random,
                random < 3 ? random + 3 : random
              )
              ?.map((data, index) => (
                <div key={index} className="col-lg-4 col-md-6 col-12">
                  <div className="cs_post cs_style_1 facility-card">
                    <div
                      style={{
                        backgroundImage: `url(${data?.facility_image})`,
                        backgroundPosition: "top center",
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        height: "220px",
                        width: "100%",
                        cursor: "pointer",
                      }}
                    />
                    <div className="cs_post_info">
                      <div className="facilities-home">
                        <h2 className="cs_post_title cs_semibold cs_fs_32">
                          {data?.facility_title}
                        </h2>
                        <p
                          dangerouslySetInnerHTML={{
                            __html:
                              data?.facility_description &&
                              truncateText(data?.facility_description, 110),
                          }}
                        />

                        <button onClick={() => handleShow(index)}>
                          Read More
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ))
          )}
          </Slider>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="review-modal-title">
            {facilitiesData[facilitiesIndex]?.facility_title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* {facilitiesData[facilitiesIndex]?.facility_description} */}
          <div
            dangerouslySetInnerHTML={{
              __html: facilitiesData[facilitiesIndex]?.facility_description,
            }}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="review-modal-button"
            variant="secondary"
            onClick={handleClose}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default HomeFacilities;
