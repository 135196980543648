import React from "react";
import Spinner from "../../../components/Spinner";

function BasicAppointment({ basicAppointment }) {
  return (
    <div>
      <h6 className="mt-5">Basic Appointment</h6>

      {!basicAppointment ? (
        <div
          style={{ marginBottom: "100px" }}
          className="d-flex align-items-center justify-content-center"
        >
          <Spinner />
        </div>
      ) : basicAppointment?.length === 0 ? (
        <div>
          <div className="table-responsive">
            <table className="table">
              <thead className="thead">
                <tr>
                  <th>Doctor Name</th>
                  <th>Requested</th>
                  <th>Approved</th>
                  <th>Action</th>
                </tr>
              </thead>
            </table>
          </div>
          <div className="alert text-center fw-bold alert-danger" role="alert">
            No Today Basic Appointments Found
          </div>
        </div>
      ) : (
        <div className="table-responsive">
          <table className="table">
            <thead className="thead">
              <tr>
                <th>Doctor Name</th>
                <th>Requested</th>
                <th>Approved</th>
                <th>Action</th>
              </tr>
            </thead>

            <tbody>
              {basicAppointment.map((appointment) => (
                <tr key={appointment?.uid}>
                  <td>{appointment?.doctor}</td>
                  <td>
                    {appointment?.requested_time +
                      "(" +
                      appointment?.shift +
                      ")"}
                  </td>
                  <td>{appointment?.approved_date}</td>
                  <td>
                    <button data-toggle="modal" data-target="#exampleModal">
                      View
                    </button>
                    <div
                      class="modal fade"
                      id="exampleModal"
                      tabindex="-1"
                      role="dialog"
                      aria-labelledby="exampleModalLabel"
                      aria-hidden="true"
                    >
                      <div class="modal-dialog" role="document">
                        <div class="modal-content">
                          <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">
                              Appointment Detail
                            </h5>
                            <button
                              type="button"
                              class="close"
                              data-dismiss="modal"
                              aria-label="Close"
                            >
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                          <div class="modal-body">
                            <p>Details here</p>
                          </div>
                          <div class="modal-footer">
                            <button
                              type="button"
                              class="btn btn-secondary"
                              data-dismiss="modal"
                            >
                              Close
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

export default BasicAppointment;
