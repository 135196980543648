import React, { useEffect, useState } from "react";
import Select from "react-select";
import Header from "./Header";
import Footer from "./Footer";
import { useSelector } from "react-redux";
import Captcha from "./Captcha/Captcha";
import { SendPostRequest } from "../http";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { capitalize } from "@mui/material";

function Signup() {
  const sitedata = useSelector((store) => store.global.loginData);

  useEffect(() => {
    const title = sitedata?.site_title || "";
    document.title = capitalize(title) + " :: Signup - ClinTa";
    window.scrollTo(0, 0);
    handleRefresh();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await SendPostRequest("hospitals/department");
        if (res.status === "success" && res?.data && res?.data?.Departments) {
          const formattedOptions = res?.data?.Departments.map((department) => ({
            value: department,
            label: department,
          }));
        } else {
          console.error("Invalid or empty response from the API");
        }
      } catch (error) {
        console.error("Error fetching departments:", error);
      }
    };

    fetchData();
  }, []);

  const genderOptions = [
    { value: "male", label: "Male" },
    { value: "female", label: "Female" },
    { value: "other", label: "Other" },
  ];

  const [captcha, setCaptcha] = useState("");
  const [input_captcha, setInputCaptcha] = useState("");
  const [check_captcha, setCheckCaptcha] = useState("");

  const navigate = useNavigate();

  const textColorClass =
    check_captcha === "Captcha Verified" ? "text-success" : "text-danger";
  const handleInputChange = (e) => {
    setInputCaptcha(e.target.value);
  };

  const handleRefresh = () => {
    const newCaptcha = generateRandomCaptcha();
    setCaptcha(newCaptcha);
  };

  const generateRandomCaptcha = () => {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const captchaLength = 6;
    let newCaptcha = "";

    for (let i = 0; i < captchaLength; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      newCaptcha += characters.charAt(randomIndex);
    }

    return newCaptcha;
  };

  const [inputValues, setInputValues] = useState({});
  const [gender, setGender] = useState(null);

  const [loading, setLoading] = useState(false);

  const handleGenderChange = (selectedOption) => {
    setGender(selectedOption.value);
  };

  const handleAllInputChange = (e) => {
    const { name, value } = e.target;
    setInputValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!inputValues?.full_name) {
      toast.error("Please enter your name");
      return;
    } else if (!gender) {
      toast.error("Please select your gender");
      return;
    } else if (!inputValues?.email) {
      toast.error("Please enter your email");
      return;
    } else if (
      !inputValues.email.includes(".") ||
      !inputValues.email.includes("@")
    ) {
      toast.error("Invalid email");
      return;
    } else if (!inputValues?.contact_number) {
      toast.error("Please enter your contact number ");
      return;
    } else if (inputValues.contact_number?.length !== 11) {
      toast.error("Invalid Number ");
      return;
    } else if (isNaN(inputValues?.contact_number)) {
      toast.error("Invalid number");
      return;
    } else if (!inputValues?.age) {
      toast.error("Please enter your age");
      return;
    } else if (isNaN(inputValues.age)) {
      toast.error("Invalid age");
      return;
    } else if (!input_captcha) {
      toast.error("You haven't enter captcha");
      return;
    }

    if (captcha === input_captcha) {
      setLoading(true);
      setCheckCaptcha("Captcha Verified");

      try {
        const myallInputs = {
          ...inputValues,
          gender,
        };
        const response = await SendPostRequest("auth/signup", myallInputs);
        setInputValues({
          full_name: "",
          email: "",
          contact_number: "",
          age: "",
        });
        setLoading(false);
        setGender({ value: "" });
        handleRefresh();
        setInputCaptcha("");
        setCheckCaptcha("");
        // setInputCaptcha(null)
        setInputCaptcha([]);

        if (response.status === "success") {
          toast.success(response.message);
          navigate("/login");
        } else {
          toast.error(response.message);
        }
      } catch (error) {
        toast.error("Something Went Wrong");
      }
    } else {
      setCheckCaptcha("Captcha failed");
      toast.error("Captcha verification failed");
    }
  };

  const [backgroundImage, setBackgroundImage] = useState("");
  let themeColor;
  if (sitedata) {
    themeColor = sitedata?.theme_color?.split(".css").join("");
  } else {
  }
  useEffect(() => {
    let themeColor;
    if (sitedata) {
      themeColor = sitedata?.theme_color?.split(".css").join("");
    } else {
    }

    setBackgroundImage(`/assets/img/doctors/banner_bg_${themeColor}.svg`);
  }, [sitedata?.theme_color]);

  return (
    <>
      <Header />
      <section
        className="cs_banner cs_style_3 cs_bg_filed"
        // style={{
        //   backgroundImage:
        //     sitedata.site_type === "hospitals"
        //       ? "url('/assets/img/doctors/banner_bg.svg')"
        //       : "url('/assets/img/doctors/banner_bg_gold.svg')",
        // }}

        style={{ backgroundImage: `url(${backgroundImage})` }}
      >
        <div className="container">
          <div className="cs_banner_text m-0">
            <h2 className="cs_banner_title cs_fs_72">Sign Up</h2>
          </div>
        </div>
        {/* <div className="cs_banner_img">
          <img src="/assets/img/appointments/banner_img.png" alt="Banner" />
        </div> */}
      </section>

      <section>
        <div className="cs_height_100 cs_height_xl_100 cs_height_lg_50"></div>
        <div className="container signup-form">
          <div className="row ">
            <div className="cs_height_42 cs_height_xl_25"></div>
            <div className="row">
              <div className="col-lg-7">
                <h2 className="cs_fs_40 cs_medium mb-4">Sign Up</h2>
                <div className="row">
                  <div className="col-lg-6">
                    <label className="cs_input_label cs_heading_color">
                      Full Name <span className="asterisk">*</span>
                    </label>

                    <input
                      type="text"
                      className="cs_form_field"
                      placeholder="Enter Full Name"
                      name="full_name"
                      value={inputValues.full_name}
                      onChange={handleAllInputChange}
                      required
                    />
                    <div className="cs_height_42 cs_height_xl_25"></div>
                  </div>

                  <div className="col-lg-6">
                    <label className="cs_input_label cs_heading_color">
                      Please Select Gender <span className="asterisk">*</span>
                    </label>
                    <Select
                      className="gallery-select-field"
                      name="gender"
                      value={gender?.value}
                      options={genderOptions}
                      onChange={handleGenderChange}
                      placeholder="Select Gender"
                      required
                    />

                    <div className="cs_height_42 cs_height_xl_25"></div>
                  </div>

                  <div className="col-lg-6">
                    <label className="cs_input_label cs_heading_color">
                      Email <span className="asterisk">*</span>
                    </label>
                    <input
                      type="email"
                      className="cs_form_field"
                      placeholder="Enter Your Email"
                      name="email"
                      value={inputValues.email}
                      onChange={handleAllInputChange}
                      required
                    />
                    <div className="cs_height_42 cs_height_xl_25"></div>
                  </div>
                  <div className="col-lg-6">
                    <label className="cs_input_label cs_heading_color">
                      Contact Number <span className="asterisk">*</span>
                    </label>
                    <input
                      type="number"
                      className="cs_form_field"
                      value={inputValues.contact_number}
                      placeholder="Enter Contact Number"
                      name="contact_number"
                      onChange={handleAllInputChange}
                      required
                    />
                    <div className="cs_height_42 cs_height_xl_25"></div>
                  </div>

                  <div className="col-lg-6">
                    <label className="cs_input_label cs_heading_color">
                      Age <span className="asterisk">*</span>
                    </label>
                    <input
                      type="number"
                      className="cs_form_field"
                      placeholder="Enter Your Age"
                      name="age"
                      value={inputValues.age}
                      onChange={handleAllInputChange}
                      required
                    />
                    <div className="cs_height_42 cs_height_xl_25"></div>
                  </div>
                  <div className="col-lg-6">
                    <Captcha
                      captcha={captcha}
                      inputCaptcha={input_captcha}
                      textColorClass={textColorClass}
                      checkCaptcha={check_captcha}
                      handleInputChange={handleInputChange}
                    />
                  </div>
                  <div className="col-lg-12">
                    <div className="cs_height_18"></div>
                    <button
                      className="cs_btn cs_style_1"
                      onClick={handleSubmit}
                      disabled={loading}
                    >
                      <span>{loading ? "Please wait" : "Sing up"}</span>
                      <i>
                        <img
                          src="/assets/img/icons/arrow_white.svg"
                          alt="Icon"
                        />
                        <img
                          src="/assets/img/icons/arrow_white.svg"
                          alt="Icon"
                        />
                      </i>
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 offset-lg-1">
                <h2 className="cs_fs_40 cs_medium mb-0">Sign Up Query</h2>
                <div className="cs_height_60 cs_height_xl_40"></div>
                <img
                  src="/assets/img/appointments/appointment_img.jpg"
                  alt="Appointment"
                  className="cs_radius_25 w-100"
                />
                <div className="cs_height_100 cs_height_xl_60"></div>
                <ul className="cs_contact_info cs_style_1 cs_mp0">
                  <li>
                    <h3 className="cs_fs_24 cs_semibold mb-0">Phone</h3>
                    <p className="cs_iconbox_subtitle mb-0 cs_heading_color">
                      <a href="tel:+92515732855">051 5732 855</a>
                    </p>
                  </li>
                  <li>
                    <h3 className="cs_fs_24 cs_semibold mb-0">Email Us</h3>
                    <p className="cs_iconbox_subtitle mb-0 cs_heading_color">
                      <a href="mailto:info@reliancehospital.org">
                        info@reliancehospital.org
                      </a>
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="cs_height_42 cs_height_xl_25"></div>
      <div className="cs_height_72"></div>
      <Footer showAppointmentbutton={true} />
    </>
  );
}

export default Signup;
